
import React from "react";
import { useLocation } from "react-router-dom";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { indigo, grey, teal, blue, green, pink, lightGreen, lime } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import widgetConfirm from "./widget_confirm";
import widgetWaiting from "./widget_waiting";
import axios from "axios";
import base64url from "base64url";
import { Buffer } from "buffer";
global.Buffer = global.Buffer || Buffer;

// https://mui.com/customization/palette/
// https://mui.com/customization/color/
const mainTheme = createTheme( {
	palette: {
		//info: { main: indigo[ 400 ], },
		mode: "light",
		myprim: { main: grey[ 300 ], dark: grey[ 600 ], light: grey[ 200 ] },
		mydark: { main: grey[ 800 ], dark: grey[ 900 ], light: grey[ 600 ] },
		mytheme: lightGreen[ 800 ],
	},
} );
/*
const mainTheme = createTheme( {
	palette: {
		//info: { main: indigo[ 400 ], },
		mode: "dark",
		myprim: { main: grey[ 800 ], dark: grey[ 600 ], light: grey[ 100 ] },
		mytheme: lightGreen[ 800 ],
	},
} );
*/

//console.log( mainTheme );

const webTitle = document.title;

const Alert = React.forwardRef( ( props, ref ) => {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
} );

var glob = { };

glob.userNullId = -1,

glob.setTitle = ( title ) => {
	if( title ) { document.title = title + " - " + webTitle; }
	else { document.title = webTitle; }
};

glob.getStrDate = ( intDate ) => {
	var date = new Date( intDate ? intDate : 0 );
	var strMonth = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม" ];
	//return date.getDate( ) + " " + strMonth[ date.getMonth( ) ] + " " + ( date.getFullYear( ) + 543 ) + ", " +
	//	date.getHours( ).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + ":" +
	//	date.getMinutes( ).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + "น.";
	return date.getDate( ).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + "/" +
		(date.getMonth( )+1).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + "/" +
		( date.getFullYear( ) + 543 ) + ", " +
		date.getHours( ).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + ":" +
		date.getMinutes( ).toLocaleString( "en-US", { minimumIntegerDigits: 2, useGrouping: false } ) + "น.";
};

glob.toHtmlNewLine = ( str, isXhtml ) => {
	var breakTag = ( isXhtml || typeof isXhtml === "undefined" ) ? "<br/>" : "<br>";
	return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
};

glob.clamp = ( num, min, max ) => { return Math.min( Math.max( num, min ), max ) };
glob.getUserUrl = ( has, name, userId ) => { return has ? "/assets/user/" + userId + "/" + name + ".webp" : "/assets/default/" + name + ".webp"; };

glob.getPageData = ( ) => {
	var pageNo = parseInt( glob.urlSearchParams.get( "page" ) );
	pageNo = isNaN( pageNo ) ? 1 : pageNo;
	var pageOffset = (pageNo<=1) ? 0 : (pageNo-1);
	return [ pageOffset, pageNo ];
}

glob.init = ( ) => {
//	console.log( "glob.init" );
	//
	glob.state = React.useRef( "idle" );
	glob.location = useLocation( );
	const urlSearchParams = React.useRef( new URLSearchParams( glob.location.search ) );
	glob.urlSearchParams = urlSearchParams.current;
	glob.url64 = base64url( glob.location.pathname + glob.location.search );
	/*glob.axios = axios.create( {
		baseURL: "http://localhost:3001/",
	} );*/
	// glob.isReady
	const waitCount = React.useRef( 1 );
	const [ isReady, setIsReady ] = React.useState( false );
	glob.isReady = isReady;
	const decreaseWaitCount = ( ) => {
		waitCount.current -= 1;
		if( waitCount.current <= 0 ) { setIsReady( true ); }
	}
	// verifyUser
	const verifyUser = ( ) => {
		const refreshTime = 30 * 60 * 1000; // 30 minutes
		//const refreshTime = 5 * 60 * 1000;
		//const refreshTime = 10 * 1000;
		glob.userInfo = React.useState( { } );
		//
		const refresh = React.useCallback( async ( ) => {
			await axios.post( "/backend/userRefreshToken", { } );
			setTimeout( refresh, refreshTime );
		}, [ ] );
		//
		React.useEffect( ( ) => {
			const fetchData = async ( ) => {
				var res = ( await axios.post( "/backend/userGetInfoFromCookie", { } ) ).data;
				if( res.info ) {
					res.info.urlAvatar = glob.getUserUrl( res.info.hasAvatar, "avatar", res.info.userId );
					res.info.urlCover = glob.getUserUrl( res.info.hasCover, "cover", res.info.userId );
				}
				glob.userInfo[ 1 ]( res.info ? res.info : { userId: null, } );
				setTimeout( refresh, refreshTime );
				decreaseWaitCount( );
//				console.log( res.info.permissions );
//				console.log( (res.info.permissions >> 31) & 1 );
			};
			fetchData( );
		}, [ ] );
	}
	verifyUser( );
	// snackMsg
	const initSnackMsg = ( ) => {
		const [ open, setOpen ] = React.useState( false );
		const [ message, setMessage ] = React.useState( "" );
		const [ severity, setSeverity ] = React.useState( "info" ); // success, info, warning, error
		const handleClose = ( event, reason ) => {
			if( reason === "clickaway" ) { return; }
			setOpen( false );
		};
		//
		glob.refreshSnack = React.useState( { } );
		React.useEffect( ( ) => {
			if( glob.refreshSnack[ 0 ].snackMsg ) {
				setSeverity( glob.refreshSnack[ 0 ].snackType ? glob.refreshSnack[ 0 ].snackType : "info" );
				setMessage( glob.refreshSnack[ 0 ].snackMsg );
				setOpen( true );
			}
			else if( glob.location.state ) {
				if( glob.location.state.snackMsg ) {
					if( glob.location.state.snackType ) { setSeverity( glob.location.state.snackType ); }
					setMessage( glob.location.state.snackMsg );
					setOpen( true );
				}
			}
		}, [ glob.refreshSnack[ 0 ] ] );
		//
		// React.useEffect( ( ) => { console.log( glob.location ); }, [ ] );
		//
		return (
			<Snackbar open={ open } autoHideDuration={ 4000 } onClose={ handleClose }>
				<Alert onClose={ handleClose } severity={severity} sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>
		);
	}
	glob.renderSnackMsg = initSnackMsg( );
	glob.renderConfirm = widgetConfirm.init( );
	glob.renderWaiting = widgetWaiting.init( );
	//
	const navigate = useNavigate( );
	// glob.nextPage
	const [ nextPageVar, nextPage ] = React.useState( null );
	glob.nextPage = nextPage;
	React.useEffect( ( ) => {
		if( nextPageVar ) {
			const h = nextPageVar;
			//var param = h[ 1 ] ? h[ 1 ] : { };
			//param.state = param.state ? param.state : { };
			//param.state.previousUrl = glob.location.pathname;
			navigate( h[ 0 ], h[ 1 ] );
		}
	}, [ nextPageVar ] );
	//
	glob.formatContent = [
		"bold", "italic", "underline", "strike",
		"script", "blockquote", "formula", "code-block",
		"list", "align", "link", "image", "video",
	];
};

glob.render = ( renderPage ) => {
	return glob.isReady ? (
		<ThemeProvider theme={ mainTheme }>
			{ renderPage }
			{ glob.renderConfirm }
			{ glob.renderWaiting }
			{ glob.renderSnackMsg }
		</ThemeProvider>
	) : null;
};

export default glob;
