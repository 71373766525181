
import React from "react";
import { useParams } from "react-router-dom";
import { Box, Avatar, Divider, Container, Typography, Link, Button, TextField } from "@mui/material";
import axios from "axios";
//import address from "address";
import glob from "./glob";
import katex from "katex";
import "katex/dist/katex.min.css";
import hljs from "highlight.js/lib/common";
import Quill from "quill";
import "highlight.js/styles/stackoverflow-dark.css";
import "quill/dist/quill.snow.css";
import "./quill.override.css";
window.katex = katex;
var Delta = Quill.import( "delta" );
//import SnowTheme from "quill/themes/snow";
import BaseTheme from "quill/themes/base";

//class ReadonlyTheme extends SnowTheme {
class ReadonlyTheme extends BaseTheme {
	constructor(quill, options) {
		super( quill, options );
		this.quill.container.classList.add( "ql-readonly" );
	}
};
Quill.register( "themes/readonly", ReadonlyTheme, true );

export default (props) => {
	var {ops, blogId, lastUpdated} = props;
	var strLastUpdated = (lastUpdated > 0) ? ("แก้ไขล่าสุดเมื่อ " + glob.getStrDate(lastUpdated) ) : null;
	var containerId = "editor-container-" + blogId;
	var content = React.useRef(new Delta());
	var quill = React.useRef(null);
	//
	React.useEffect(() => {
		content.current.ops = ops;
		if(quill.current) {quill.current.setContents(content.current);}
	}, [ops]);
	//
	React.useEffect(() => {
		//var container = document.getElementById("#editor-container");
		//quill.current = new Quill(container, {
		quill.current = new Quill("#"+containerId, {
			theme: "snow",
			modules: {
				syntax: {highlight: text => hljs.highlightAuto(text).value,},
				toolbar: false,
			},
			formats: glob.formatContent,
			toolbar: {container: [], handlers: {},},
			readOnly: true,
		});
		quill.current.setContents(content.current);
	}, [blogId]);
	//
	return (
		<div>
			<Box className="ql-override ql-readonly" sx={{display: "flex", mt:2, mb:2}}>
				<div id={containerId}/>
			</Box>
			{strLastUpdated ? ( <Typography variant="body2" color="myprim.dark" sx={{fontStyle: "italic"}}>{strLastUpdated}</Typography> ) : null}
		</div>
	);
};
