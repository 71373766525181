
import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Typography, Button, TextField } from "@mui/material";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";
//import axios from "axios";

export default ( ) => {
	glob.init( );
	//
	const renderHeaderbar = widgetHeaderbar.init( );
	const [ msg, setMsg ] = React.useState( "ไม่มีไรในนี้ *o*" );
	const location = useLocation( );
	//const testna = React.useState( [ ] );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Nowhere" );
		if( location.state ) { setMsg( location.state.msg ); }
		//
		/*await axios.get( "/testGet" ).then( ( res ) => {
			console.log( res.data );
		} ).catch( ( error ) => {
			console.log( error );
		} );
		console.log( "^3^" );*/
		//
	}, [ glob.isReady ] );
	//
	return glob.render(
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md">
				<Box sx={ { mt: 8, display: "flex", flexDirection: "column", alignItems: "center" } }>
					<Typography>{ msg }</Typography>
					<p/>
					<Button variant="contained" color="myprim" href="/">home</Button>
					{/* testna[ 0 ] */}
				</Box>
			</Container>
		</div>
	);
};

