
import React from "react";
import { Box, Typography, Paper, Grid, IconButton, Link, Tooltip } from "@mui/material";
import glob from "./glob";
import BlogControl, {addMoreBlogData} from "./props_blog_control";

export default (props) => {
	var {disableStatus, blogs, lUser} = props;
	const wgs = React.useState([]);
	//
	const setWgs = () => {
		var widgets = [], i = 0;
		//
		const push = (offset, blog) => {
			var wg = {};
			wg.renType = blog.isDeleted ? "empty" : "blog";
			wg.blog = blog;
			wg.offset = offset;
			//
			widgets.push( wg );
			return offset + 1;
		};
		//
		while(i < blogs.length) {
			var blog = blogs[ i ];
			i = push( i, blog );
		}
		//
		wgs[1](widgets);
	};
	//
	React.useEffect(() => {
		blogs.map((blog, index) => {addMoreBlogData(blog, lUser);});
		setWgs();
	}, []);
	//
	const renWgs = ( wg, index ) => {
		switch(wg.renType) {
			case "empty": {
				return( <div key={index}/> );
				break;
			};
			case "blog": {
				return(
					<BlogControl key={index} blog={wg.blog} disableStatus={disableStatus} onDeleted={(err) => {
						if(err) {glob.refreshSnack[1]({snackType: "error", snackMsg: err});}
						else {glob.refreshSnack[1]({snackType: "success", snackMsg: "ลบเรียบร้อยแล้ว"});}
						//blogs.splice(wg.offset, 1);
						wg.blog.isDeleted = true;
						setWgs();
					}}/>
				);
				break;
			};
		};
	};
	//
	return(
		( wgs[0].length > 0 ) ? wgs[0].map( renWgs ) : (
			<Typography sx={{mt:1}}>ไม่มีแล้วอ่า</Typography>
		)
	);
};

