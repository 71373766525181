
import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box, Container, Typography, Grid, TextField, Avatar, Link, Input, Button } from "@mui/material";
import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import widgetHeaderbar from "./widget_headerbar";
import glob from "./glob";

export default ( ) => {
	glob.init( );
	const params = useParams( );
	const renderHeaderbar = widgetHeaderbar.init( );
	const [ state, setState ] = React.useState( "wait" );
	const [ email, setEmail ] = React.useState( "" );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		const fetchData = async ( ) => {
			glob.setTitle( "Register" );
			var obj = { id: params.id, passRaw: params.passRaw };
			var res = await axios.post( "/backend/userCheckConfirmEmail", obj );
			res = res.data;
			if( res.isSuccess ) {
				setEmail( res.email );
				setState( "idle" );
			}
			else {
				glob.nextPage( [ "/nowhere", { state: { msg: "เกิดความผิดพลาด: link ไม่ถูกต้อง อาจจะเคยเปิดไปแล้ว หรืออาจหมดอายุแล้ว หรือ ฯลฯ" } } ] );
			}
		}
		fetchData( );
	}, [ glob.isReady ] );
	//
	const [ errUserName, setErrUserName ] = React.useState( "" );
	const [ errPassword, setErrPassword ] = React.useState( "" );
	const [ errPassword2, setErrPassword2 ] = React.useState( "" );
	const submitPressed = async ( event ) => {
		event.preventDefault( );
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var form = event.target;
		var obj = { };
		obj.email = email;
		obj.userName = form.elements[ "userName" ].value;
		obj.password = form.elements[ "password" ].value;
		obj.password2 = form.elements[ "password2" ].value;
		//
		var res = await axios.post( "/backend/userRegister", obj );
		res = res.data;
		if( res.isSuccess ) {
			//glob.nextPage( [ "/", { state: { snackType: "success", snackMsg: "สมัครสมาชิกสำเร็จแล้ว ท่านสามารถ login เพื่อเข้าใช้งานได้เลย" } } ] );
			glob.nextPage( [ "/nowhere", { state: { msg: "สมัครสมาชิกสำเร็จแล้ว ท่านสามารถ login เพื่อเข้าใช้งานได้เลย" } } ] );
		}
		else if( res.errorOther != "" ) {
			glob.nextPage( [ "/nowhere", { state: { msg: res.errorOther } } ] );
		}
		else {
			setErrUserName( res.errUserName );
			setErrPassword( res.errPassword );
			setErrPassword2( res.errPassword2 );
			glob.state.current = "idle";
		}
	};
	//
	const renIdle = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="xs">
				<Box sx={{mt: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<Box sx={{display: "flex", alignItems: "baseline"}}>
						<Avatar sx={{mr: 1, bgcolor: "mytheme"}}>
							<PersonAddIcon/>
						</Avatar>
						<Typography component="h1" variant="h4">Register</Typography>
					</Box>
					<Box sx={{mt:2}}>
						<form noValidate id="fromSignup" onSubmit={submitPressed}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										autoComplete="uname" name="userName" variant="outlined" required fullWidth
										error={ errUserName !== "" } id="userName" label="UserName" helperText={ errUserName }
										onChange={ ( event ) => {
											setErrUserName( "" );
											//console.log( event.target.value );
										} }
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField variant="outlined" required fullWidth name="password" label="Password" type="password" id="password"
										autoComplete="current-password"
										error={ errPassword !== "" }
										helperText={ errPassword }
										onChange={ ( event ) => { setErrPassword( "" ); } }
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField variant="outlined" required fullWidth name="password2" label="Confirm Password" type="password" id="password2"
										autoComplete="current-password"
										error={ errPassword2 !== "" }
										helperText={ errPassword2 }
										onChange={ ( event ) => { setErrPassword2( "" ); } }
									/>
								</Grid>
								<Grid item xs={12} sm={4} sx={{ mt: 1}}>
									<Button fullWidth variant="contained" color="myprim" type="submit" form="fromSignup">Register</Button>
								</Grid>
								<Grid item xs={12} sm={8} sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
									<Typography>หรือ</Typography>
									<Link href="/login">Log in</Link>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Box>
			</Container>
		</div>
	);
	//
	return glob.render( ( state === "wait") ? ( <div/> ) : ( <div> { renIdle } </div> ) );
};

