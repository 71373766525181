
import React from "react";
import axios from "axios";
import { Avatar, Divider, Tooltip, IconButton, Menu, MenuItem, Grid, ListItemIcon, ListItemText, Typography, Box } from "@mui/material";
import {
	Person as PersonIcon,
	Login as LoginIcon,
	Logout as LogoutIcon,
	PersonAdd as PersonAddIcon,
	AccountCircle as AccountCircleIcon,
	Settings as SettingsIcon,
} from "@mui/icons-material";
import glob from "./glob";

var widgetUserAvatar = { };

widgetUserAvatar.init = ( ) => {
	const menuSx = {
		overflow: "visible",
		filter: "drop-shadow( 0px 2px 8px rgba( 0, 0, 0, 0.32 ) )",
		mt: 1.5,
		"& .MuiAvatar-root": { width: 32, height: 32, ml: -0.5, mr: 1, },
		"&:before": {
			content: '""', display: "block", position: "absolute",
			top: 0, right: 14, width: 10, height: 10,
			bgcolor: "background.paper", transform: "translateY(-50%) rotate(45deg)", zIndex: 0,
		},
	};
	//
	const initMember = ( ) => {
		const [ anchorEl, setAnchorEl ] = React.useState( null );
		const open = Boolean( anchorEl );
		const handleClick = ( event ) => { setAnchorEl( event.currentTarget ); };
		const handleClose = ( ) => { setAnchorEl( null ); };
		const onClickLockout = async ( event ) => {
			//console.log( glob.state.current );
			if( glob.state.current !== "idle" ) { return; }
			glob.state.current = "wait";
			await axios.post( "/backend/userClearAuth", { } );
			glob.userInfo[ 1 ]( { userId: null } );
			//glob.nextPage( [ location.pathname, { replace: true, state: { snackType: "success", snackMsg: "Logout เรียบร้อยแล้ว" } } ] );
			//glob.refreshSnack[ 1 ]( { } );
			glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "Logout เรียบร้อยแล้ว" } );
			glob.state.current = "idle";
		};
		//
		return (
			<div>
				<Tooltip title="สมาชิก">
					<IconButton onClick={ handleClick } size="small" sx={{ ml: 2 }}>
						<Avatar src={ glob.userInfo[ 0 ].userId ? glob.userInfo[ 0 ].urlAvatar : null } />
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={ anchorEl } open={ open } onClose={ handleClose } onClick={ handleClose }
					PaperProps={ { elevation: 0, sx: menuSx, } }
					transformOrigin={ { horizontal: "right", vertical: "top" } }
					anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
				>
					<MenuItem component="a" href={"/user/"+glob.userInfo[ 0 ].userName}>
						<Grid sx={{ display: "flex" }}>
							<ListItemIcon>
								<AccountCircleIcon fontSize="small"/>
							</ListItemIcon>
							<ListItemText>{ glob.userInfo[ 0 ].userId ? glob.userInfo[ 0 ].displayName : "????" }</ListItemText>
						</Grid>
					</MenuItem>
					<MenuItem component="a" href="/setting_profile">
						<Grid sx={{ display: "flex" }}>
							<ListItemIcon>
								<SettingsIcon fontSize="small"/>
							</ListItemIcon>
							<ListItemText>Settings</ListItemText>
						</Grid>
					</MenuItem>
					<Divider/>
					<MenuItem onClick={ onClickLockout }>
						<Grid sx={{ display: "flex" }}>
							<ListItemIcon>
								<LogoutIcon fontSize="small"/>
							</ListItemIcon>
							<ListItemText>Log out</ListItemText>
						</Grid>
					</MenuItem>
				</Menu>
			</div>
		);
	};
	const renMember = initMember( );
	//
	const initLogin = ( ) => {
		const [ anchorEl, setAnchorEl ] = React.useState( null );
		const open = Boolean( anchorEl );
		const handleClick = ( event ) => { setAnchorEl( event.currentTarget ); };
		const handleClose = ( ) => { setAnchorEl( null ); };
		const url = glob.location.pathname === "/login" ? "/login" : ("/login?redirect=" + glob.url64);
		//
		return (
			<div>
				<Tooltip title="สมาชิก">
					<IconButton onClick={ handleClick } size="small" sx={{ ml: 2 }}>
						<Avatar><PersonIcon /></Avatar>
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={ anchorEl } open={ open } onClose={ handleClose } onClick={ handleClose }
					PaperProps={ { elevation: 0, sx: menuSx, } }
					transformOrigin={ { horizontal: "right", vertical: "top" } }
					anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
				>
					<MenuItem component="a" href={url}>
						<Grid sx={{ display: "flex" }}>
							<ListItemIcon>
								<LoginIcon fontSize="small"/>
							</ListItemIcon>
							<ListItemText>Log in</ListItemText>
						</Grid>
					</MenuItem>
					<MenuItem component="a" href="/signup_email">
						<Grid sx={{ display: "flex" }}>
							<ListItemIcon>
								<PersonAddIcon fontSize="small"/>
							</ListItemIcon>
							<ListItemText>Register</ListItemText>
						</Grid>
					</MenuItem>
				</Menu>
			</div>
		);
	};
	const renLogin = initLogin( );
	//
	return ( glob.userInfo[ 0 ].userId ? (<div>{renMember}</div>) : (<div>{renLogin}</div>) );
};

export default widgetUserAvatar;

