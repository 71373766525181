
import React from "react";
import { useParams } from "react-router-dom";
import { Avatar, Box, Badge, Grid, Card, CardMedia, Paper, Container, Typography, Button, Tooltip, CircularProgress, IconButton, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { Save as SaveIcon, Send as SendIcon, InsertPhoto as InsertPhotoIcon, Refresh as RefreshIcon, Clear as ClearIcon } from "@mui/icons-material";
import axios from "axios";
import dataURLtoBlob from "dataurl-to-blob";
import { useBeforeunload } from "react-beforeunload";
import glob from "./glob";
import katex from "katex";
import "katex/dist/katex.min.css";
import hljs from "highlight.js/lib/common";
import Quill from "quill";
import "highlight.js/styles/stackoverflow-dark.css";
import widgetHeaderbar from "./widget_headerbar";
import widgetWaiting from "./widget_waiting";
import { initWidgetCropImage, cropImgAddFormData } from "./widget_crop_image";
import widgetConfirm from "./widget_confirm";
import "quill/dist/quill.snow.css";
import "./quill.override.css";
window.katex = katex;
var Delta = Quill.import( "delta" );
var icons = Quill.import( "ui/icons" );
icons[ "imageUrl" ] = "<svg viewbox=\"0 0 18 18\"> <rect class=ql-stroke height=10 width=12 x=3 y=4></rect> <circle class=ql-fill cx=6 cy=7 r=1></circle> <polyline class=\"ql-even ql-fill\" points=\"5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12\"></polyline> </svg>";

export default ( ) => {
	const isWaitSave = React.useState( false );
	// WidgetComment
	const initWidgetComment = ( ) => {
		var obj = { };
		obj.isChanged = React.useState( false );
		obj.canComment = React.useState( true );
		obj.canGuestComment = React.useState( true );
		obj.cmOld = React.useRef( true );
		obj.cgmOld = React.useRef( true );
		//
		obj.init = ( canComment, canGuestComment ) => {
			obj.cmOld.current = canComment ? true : false;
			obj.cgmOld.current = canGuestComment ? true : false;
			obj.canComment[ 1 ]( obj.cmOld.current );
			obj.canGuestComment[ 1 ]( obj.cgmOld.current );
		};
		//
		obj.save = async ( ) => {
			if( !obj.isChanged[ 0 ] ) { return null; }
			var src = { };
			src.userId = glob.userInfo[ 0 ].userId;
			src.blogId = blogId;
			src.canComment = obj.canComment[ 0 ];
			src.canGuestComment = obj.canGuestComment[ 0 ];
			var res = ( await axios.post( "/backend/blogEditSaveCommentOption", src ) ).data;
			if( !res.err ) {
				obj.cmOld.current = obj.canComment[ 0 ];
				obj.cgmOld.current = obj.canGuestComment[ 0 ];
				obj.isChanged[ 1 ]( false );
			}
			return res.err;
		};
		//
		React.useEffect( ( ) => {
			obj.isChanged[ 1 ]( ( obj.canComment[ 0 ] !== obj.cmOld.current ) || ( obj.canGuestComment[ 0 ] !== obj.cgmOld.current ) );
		}, [ obj.canComment[ 0 ], obj.canGuestComment[ 0 ] ] );
		//
		obj.render = (
			<Box sx={{display: "flex", gap:1}}>
				<div><FormControlLabel control={<Checkbox color="default" checked={obj.canComment[0]} onChange = { ( event ) => {
					obj.canComment[ 1 ]( event.target.checked );
				}} />} label="เปิดคอมเม้นต์" /></div>
				<div><FormControlLabel control={<Checkbox color="default" checked={obj.canGuestComment[0]} onChange = { ( event ) => {
					obj.canGuestComment[ 1 ]( event.target.checked );
				}} />} label="Guest คอมเม้นต์ได้" /></div>
			</Box>
		);
		//
		return obj;
	};
	// WidgetCover
	const initWidgetCover = ( ) => {
		var obj = { };
		obj.isChanged = React.useState( false );
		obj.originalUrl = React.useRef( null );
		obj.url = React.useState( null );
		obj.isShowBadge = React.useState( false );
		obj.isRefreshIcon = React.useState( false );
		//
		obj.cropData = React.useRef( { } );
		obj.onCropClick = ( event ) => {
			if( glob.state.current !== "idle" ) { return; }
			wgCrop.startCrop( "ตัดแต่งรูปหน้าปก", 1.0 / 1.0 );
			wgCrop.onClose.current = ( err, area, fileUrl ) => {
				if( err ) { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: err } ); return; }
				if( !fileUrl ) { return };
				obj.cropData.current.area = area;
				obj.cropData.current.img = wgCrop.dataImg.current;
				obj.url[ 1 ]( fileUrl );
				obj.isShowBadge[ 1 ]( true );
				if( obj.originalUrl.current ) { obj.isRefreshIcon[ 1 ]( true ); }
				else { obj.isRefreshIcon[ 1 ]( false ); }
				obj.isChanged[ 1 ]( true );
			};
		}
		//
		obj.init = ( hasCover ) => {
			obj.originalUrl.current = hasCover ? ( "/assets/blog/" + blogId + "/cover.webp" ) : null;
			obj.url[ 1 ]( obj.originalUrl.current );
			obj.isShowBadge[ 1 ]( hasCover );
			obj.isRefreshIcon[ 1 ]( false );
		};
		//
		obj.onClickBadge = ( ) => {
			if( obj.isRefreshIcon[ 0 ] ) {
				obj.url[ 1 ]( obj.originalUrl.current );
				obj.isRefreshIcon[ 1 ]( false );
				obj.isChanged[ 1 ]( false );
			}
			else {
				obj.url[ 1 ]( null );
				if( obj.originalUrl.current ) {
					obj.isRefreshIcon[ 1 ]( true );
					obj.isChanged[ 1 ]( true );
				}
				else {
					obj.isShowBadge[ 1 ]( false );
					obj.isChanged[ 1 ]( false );
				}
			}
		};
		//
		obj.save = async ( ) => {
			if( !obj.isChanged[ 0 ] ) { return null; }
			var data = new FormData( );
			if( obj.url[ 0 ] ) { cropImgAddFormData( data, obj.cropData.current.img, obj.cropData.current.area ); }
			else { data.append( "image", null );  }
			data.append( "userId", glob.userInfo[ 0 ].userId );
			data.append( "blogId", blogId );
			const onUploadProgress = ( event ) => { };
			var res = ( await axios.post( "/backend/blogEditSaveCover", data, {
				headers: { "Content-Type": "multipart/form-data", }, onUploadProgress,
			} ) ).data;
			if( !res.err ) {
				if( obj.url[ 0 ] ) {
					obj.originalUrl.current = obj.url[ 0 ];
					obj.isRefreshIcon[ 1 ]( false );
				}
				else {
					obj.originalUrl.current = null;
					obj.isShowBadge[ 1 ]( false );
				}
				obj.isChanged[ 1 ]( false );
			}
			return res.err;
		};
		//
		obj.renCoverIcon = ( <InsertPhotoIcon sx={{ width: 60, height: 60 }}/> );
		obj.renCoverImg = ( <img width="80" height="80" src={obj.url[ 0 ]} alt="ภาพ" /> );
		//
		obj.render = (
			<div>
				<Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} invisible={!obj.isShowBadge[ 0 ]} badgeContent={
					<Tooltip title={obj.isRefreshIcon[ 0 ] ? "เปลี่ยนกลับเป็นรูปเดิม" : "ลบรูป"}>
						<IconButton onClick={ obj.onClickBadge } size="small" sx={ [ { color: "myprim.dark", bgcolor: "myprim.light" }, ( theme ) => ( { "&:hover": { bgcolor: "myprim.main", }, } ), ] }>
							{obj.isRefreshIcon[ 0 ] ? <RefreshIcon/> : <ClearIcon/>}
						</IconButton>
					</Tooltip>
				} >
					<Tooltip title="เลือกรูป">
						<IconButton color="myprim" onClick={ obj.onCropClick } sx={ [ { width: 80, height: 80, borderRadius: 0, color: "myprim.dark", bgcolor: "myprim.light" }, ( theme ) => ( {
							"&:hover": { bgcolor: "myprim.main", },
						} ), ] }>
							{ obj.url[ 0 ] ? obj.renCoverImg : obj.renCoverIcon }
						</IconButton>
					</Tooltip>
				</Badge>
			</div>
		);
		//
		return obj;
	};
	// WidgetTitle
	const initWidgetTitle = ( ) => {
		const textMax = 150;
		var obj = { };
		obj.isChanged = React.useState( false );
		obj.text = React.useRef( "" );
		obj.label = React.useState( "เรื่อง" );
		obj.textDefault = React.useState( "" );
		obj.helperText = React.useState( "" );
		obj.isError = React.useState( false );
		//
		obj.init = ( title ) => {
			obj.text.current = title;
			obj.textDefault[ 1 ]( title );
		};
		//
		obj.save = async ( ) => {
			if( !obj.isChanged[ 0 ] ) { return null; }
			if( obj.text.current.length > textMax ) { return "Title ยาวเกินไป" }
			var src = { };
			src.userId = glob.userInfo[ 0 ].userId;
			src.blogId = blogId;
			src.title = obj.text.current;
			var res = ( await axios.post( "/backend/blogEditSaveTitle", src ) ).data;
			if( !res.err ) { obj.isChanged[ 1 ]( false ); }
			return res.err;
		};
		//
		obj.render = (
			<TextField fullWidth sx={{alignSelf: "flex-end"}} id="title" label={obj.label[ 0 ]} variant="standard" helperText={obj.helperText[ 0 ]}
				defaultValue={obj.textDefault[ 0 ]}
				error={obj.isError[ 0 ]}
				onChange={ ( event ) => {
					var textLength = event.target.value.length;
					obj.isChanged[ 1 ]( true );
					obj.text.current = event.target.value;
					if( textLength >= (textMax - 10) ) { obj.label[ 1 ]( "เรื่อง (" + textLength + "/" + textMax + ")" ); }
					else { obj.label[ 1 ]( "เรื่อง" ); }
					obj.isError[ 1 ]( textLength > textMax );
				} }
				onFocus={ ( event ) => { } }
				onBlur={ ( event ) => { } }
			/>
		);
		return obj;
	};
	// WidgetContent
	const initWidgetContent = ( ) => {
		//
		var obj = { };
		obj.content = React.useRef( new Delta( ) );
		obj.isChanged = React.useState( false );
		obj.quill = React.useRef( null );
		obj.lImgBlob = React.useRef( { } );
		//
		obj.modules = {
			syntax: { highlight: text => hljs.highlightAuto( text ).value, },
			toolbar: {
				container: [
					["bold", "italic", "underline", "strike"],
					[ {"script": "super"}, {"script": "sub"} ],
					[ "blockquote", "formula", "code-block" ],
					[{"list": "ordered"}, {"list": "bullet"}],
					[ /*"direction",*/ { "align": "" }, { "align": "center" }, { "align": "right" }, { "align": "justify" } ],
					[ "link", "image", "imageUrl", "video" ],
					[ "clean" ]
				],
				handlers: {
//					image: imageHandler,
					imageUrl: async ( ) => {
						const tooltip = obj.quill.current.theme.tooltip;
						const range = obj.quill.current.getSelection( );
						const originalSave = tooltip.save;
						const originalHide = tooltip.hide;
						tooltip.save = ( ) => {
							var value = tooltip.textbox.value;
							//try {
								if ( value ) { obj.quill.current.insertEmbed( range.index, "image", value, "user" ); }
							//} catch { }
							tooltip.save = originalSave;
							tooltip.hide( );
						};
						// Called on hide and save.
						tooltip.hide = ( ) => {
							tooltip.save = originalSave;
							tooltip.hide = originalHide;
							tooltip.hide( );
						};
						tooltip.edit( "image" );
						tooltip.textbox.placeholder = "ลิงค์ภาพ";
					},
				},
			},
		};
		obj.init = ( ops ) => {
			obj.content.current.ops = ops;
		};
		//
		obj.quillInit = ( ) => {
			obj.quill.current = new Quill( "#editor-container", {
				theme: "snow",
				modules: obj.modules,
				formats: glob.formatContent,
				placeholder: "เริ่มเขียนได้เบย...",
				readOnly: false,
			} );
			obj.quill.current.setContents( obj.content.current );
			obj.quill.current.on( "text-change", ( delta ) => {
				obj.content.current = obj.content.current.compose( delta );
				obj.isChanged[ 1 ]( true );
			} );
		};
		//
		obj.keepBlobs = ( ops ) => {
			var lBlobOld = obj.lImgBlob.current
			var lBlob = { };
			for( var i=0; i<ops.length; i++ ) {
				var insert = ops[ i ].insert;
				if( !insert ) { continue; }
				var image = insert.image;
				if( !image ) { continue; }
				if( image.slice( 0, 4 ) === "data" ) {
					var blob = dataURLtoBlob( image );
					var url = URL.createObjectURL( blob );
					const Image = Quill.import( "formats/image" );
					Image.sanitize = ( url ) => url;
					insert.image = url;
					lBlob[ url ] = blob;
				}
				else if( image.slice( 0, 4 ) === "blob" ) {
					var blob = lBlobOld[ image ];
					if( blob ) { lBlob[ image ] = blob; }
				}
				//insert.image = "/assets/default/cover2.jpg";
			}
			obj.lImgBlob.current = lBlob;
		};
		//
		obj.save = async ( ) => {
			if( !obj.isChanged[ 0 ] ) { return null; }
			const onUploadProgress = ( event ) => { };
			var images = obj.keepBlobs( obj.content.current.ops );
			var urls = [ ];
			var data = new FormData( );
			data.append( "userId", glob.userInfo[ 0 ].userId );
			data.append( "blogId", blogId );
			data.append( "ops", JSON.stringify( obj.content.current.ops ) );
			for( var url in obj.lImgBlob.current ) {
				urls.push( url );
				data.append( "images", obj.lImgBlob.current[ url ] );
			}
			data.append( "urls", JSON.stringify( urls ) );
			var res = ( await axios.post( "/backend/blogEditSaveContent", data, {
				headers: { "Content-Type": "multipart/form-data", }, onUploadProgress,
			} ) ).data;
			if( !res.err ) { obj.content.current.ops = JSON.parse( res.ops ); }
			obj.quill.current.setContents( obj.content.current );
			if( !res.err ) { obj.isChanged[ 1 ]( false ); };
			return res.err;
		};
		//
		obj.render = ( <div id="editor-container" style={{height: "65vh" }}/> );
//		obj.render = ( <Box id="editor-container" sx={{height: 200}}/> );
		return obj;
	};
	// WidgetPublish
	const initWidgetPublish = ( ) => {
		var obj = { }
		obj.name = React.useState( "Publish" );
		obj.theme = React.useState( "success" );
		obj.isEnable = React.useState( true );
		obj.isConfirm = React.useRef( true );
		//
		obj.setStatus = ( status ) => {
			if( ( status === "draft" ) || ( status === "unpublish" ) || ( status === "banned" ) ) {
				obj.name[ 1 ]( "Publish" );
				obj.theme[ 1 ]( "success" );
			}
			else if( status === "publish" ) {
				obj.name[ 1 ]( "Unpublish" );
				obj.theme[ 1 ]( "warning" );
			}
			//
			if( status === "banned" ) { obj.isEnable[ 1 ]( false ); }
			else { obj.isEnable[ 1 ]( true ); }
			//
			if( status === "unpublish" ) { obj.isConfirm.current = false; }
			else { obj.isConfirm.current = true; }
		};
		//
		obj.doPublish = async ( isConfirm ) => {
			if( !isConfirm ) { return }
			isWaitSave[1](true);
			//widgetWaiting.isOpen[ 1 ]( true );
			if( !( await saveEntry( ) ) ) { return; }
			var res = ( await axios.post( "/backend/blogEditPublish", { userId: glob.userInfo[ 0 ].userId, blogId: blogId, isPublish: true } ) ).data;
			if( res.err ) { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: res.err } ); }
			//else { glob.nextPage( [ "/", { replace: true, state: { snackType: "success", snackMsg: "Publish บทความแล้ว" } } ] ); }
			else { glob.nextPage( [ "/user/"+glob.userInfo[ 0 ].userName, { replace: true, state: { snackType: "success", snackMsg: "Publish บทความแล้ว" } } ] ); }
			//widgetWaiting.isOpen[ 1 ]( false );
			isWaitSave[1](false);
		};
		//
		obj.doUnpublish = async( ) => {
			//widgetWaiting.isOpen[ 1 ]( true );
			isWaitSave[1](true);
			if( !( await saveEntry( ) ) ) { return; }
			var res = ( await axios.post( "/backend/blogEditPublish", { userId: glob.userInfo[ 0 ].userId, blogId: blogId, isPublish: false } ) ).data;
			if( res.err ) { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: res.err } ); }
			else {
				obj.setStatus( "unpublish" );
				glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "Unpublish แล้ว" } );
			}
			//widgetWaiting.isOpen[ 1 ]( false );
			isWaitSave[1](false);
		};
		//
		obj.onClick = async ( event ) => {
			if( glob.state.current !== "idle" ) { return; }
			if( obj.name[ 0 ] === "Publish" ) {
//				if( obj.isConfirm.current ) {
					//widgetConfirm.title[ 1 ]( "Publish post? " );
					widgetConfirm.content[ 1 ]( "จะ publish จริงไหม?" );
					widgetConfirm.isOpen[ 1 ]( true );
					widgetConfirm.onClose.current = obj.doPublish;
//				}
//				else { await obj.doPublish( ); }
			}
			else {
				await obj.doUnpublish( );
			}
			//glob.state.current = "wait";
			//glob.state.current = "idle";
		};
		//
		obj.render = ( <Button variant="contained" color={ obj.theme[ 0 ] } disabled={ !obj.isEnable[ 0 ] || isWaitSave[0] } onClick={ obj.onClick } startIcon={<SendIcon />}>{ obj.name[ 0 ] }</Button> );
		return obj;
	};
	//
	glob.init( );
	const params = useParams( );
	const blogId = params.id;
	const renderHeaderbar = widgetHeaderbar.init( );
	const [ state, setState ] = React.useState( "wait" );
	const wgCrop = initWidgetCropImage( );
	const wgTitle = initWidgetTitle( );
	const wgComment = initWidgetComment( );
	const wgCover = initWidgetCover( );
	const wgContent = initWidgetContent( );
	const wgPublished = initWidgetPublish( );
	const uiBottomRef = React.useRef( );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Edit" );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		const fetchData = async ( ) => {
			if( glob.userInfo[ 0 ].userId ) {
				var res = ( await axios.post( "/backend/blogEditLoad", { userId: glob.userInfo[ 0 ].userId, blogId: blogId } ) ).data;
				if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
				wgComment.init( res.canComment, res.canGuestComment );
				wgCover.init( res.hasCover );
				wgTitle.init( res.title );
				wgContent.init( JSON.parse( res.ops ) );
				wgPublished.setStatus( res.status );
				setState( "idle" );
			}
			else if( glob.userInfo[ 0 ].userId === null ) { glob.nextPage( [ "/login?redirect="+glob.url64, { } ] ); }
		};
		fetchData( );
	}, [ glob.userInfo[ 0 ] ] );
	//
	React.useEffect( ( ) => {
		if( state === "idle" ) {
			wgContent.quillInit( );
		}
	}, [ state ] );
	//
	const saveEntry = async( event ) => {
		const errAndRet = ( err ) => { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: "Save ผิดพลาด: " + err } ); return false; };
		var err = await wgCover.save( ); if( err ) { return errAndRet( err ); };
		err = await wgComment.save( ); if( err ) { return errAndRet( err ); };
		err = await wgTitle.save( ); if( err ) { return errAndRet( err ); };
		err = await wgContent.save( ); if( err ) { return errAndRet( err ); };
		return true;
	};
	//
	const onSave = async ( event ) => {
		isWaitSave[ 1 ]( true );
		//widgetWaiting.isOpen[ 1 ]( true );
		var ret = await saveEntry( );
		//widgetWaiting.isOpen[ 1 ]( false );
		isWaitSave[ 1 ]( false );
		if( ret ) { glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "Save สำเร็จแล้ว" } ); };
	};
	// Confirm exit
	useBeforeunload( ( event ) => { if( wgComment.isChanged[ 0 ] || wgCover.isChanged[ 0 ] || wgTitle.isChanged[ 0 ] || wgContent.isChanged[ 0 ] ) { event.preventDefault( ); } } );
	//
	const ren = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{height: "100%"}}>
				<Box sx={{display: "flex", flexShrink: 1, gap:2, mt:1, mb:1}}>
					{ wgCover.render }
					{ wgTitle.render }
				</Box>
				<Box className="ql-override">
					{ wgContent.render }
				</Box>
				<Box sx={{display: "flex", mt:1}}>
					{ wgComment.render }
					<Box sx={{display: "flex", justifyContent: "flex-end", flexGrow: 1, gap:1}}>
						{ isWaitSave[0] ? <CircularProgress/> : null }
						<Button disabled={ !( wgComment.isChanged[ 0 ] || wgCover.isChanged[ 0 ] || wgTitle.isChanged[ 0 ] || wgContent.isChanged[ 0 ]) || isWaitSave[0] } variant="contained" color="myprim" onClick={ onSave } startIcon={<SaveIcon />}>Save</Button>
						{ wgPublished.render }
					</Box>
				</Box>
			</Container>
			{ wgCrop.render }
		</div>
	);
	//
	return glob.render( ( state === "wait") ? <div/> : ren );
};

