
import React from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Container, Typography, Link, Button, TextField, Paper, Grid, IconButton, Tooltip, Card, CardMedia, Avatar } from "@mui/material";
import { Comment as CommentIcon, Visibility as VisibilityIcon, StickyNote2 as StickyNote2Icon } from "@mui/icons-material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axios from "axios";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";
import BlogList from "./props_blog_list";
import PageList from "./props_page_list";

export default ( ) => {
	glob.init( );
	const params = useParams( );
	const userName = params.id;
	const renderHeaderbar = widgetHeaderbar.init( );
	const blogs = React.useState( [ ] );
	const lUser = React.useState( { } );
	const blogLength = React.useRef( 0 );
	const blogUser = React.useRef( { } );
	var isOwner = React.useState( false );
	const isUInfoReady = React.useState( false );
	const pageNum = React.useState( 0 );
	const [ pageOffset, pageNo ] = glob.getPageData( );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId === undefined ) { return; }
		const fetchData = async ( ) => {
			var res = ( await axios.post( "/backend/blogGetList", { listType: "user", pageOffset: pageOffset, userName: userName } ) ).data;
			if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
			var user = res.lUser[ Object.keys( res.lUser )[ 0 ] ];
			blogs[1](res.blogs);
			lUser[1](res.lUser);
			blogLength.current = res.blogs.length;
			//
			blogUser.current = user;
			blogUser.current.urlAvatar = glob.getUserUrl( user.hasAvatar, "avatar", user.userId );
			blogUser.current.urlCover = glob.getUserUrl( user.hasCover, "cover", user.userId );
			//
			glob.setTitle( "User " + blogUser.current.displayName );
			isOwner[ 1 ]( glob.userInfo[ 0 ].userName === userName );
			pageNum[ 1 ]( res.pageNum );
			isUInfoReady[ 1 ]( true );
		};
		fetchData( );
	}, [ glob.userInfo[ 0 ] ] );
	//
	const newPost = async ( event ) => {
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var obj = { };
		obj.userId = glob.userInfo[ 0 ].userId;
		var res = await axios.post( "/backend/blogNew", obj );
		res = res.data;
		if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); }
		else { glob.nextPage( [ "/edit/" + res.id, { } ] ); }
		//
		glob.state.current = "idle";
	}
	//
	const ren = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{mb:5}}>
				<Card variant="outlined" sx={{ maxWidth: "100%", border: 0, borderRadius: 0 }}>
					<CardMedia component="img" image={ blogUser.current.urlCover } alt="ภาพ" />
				</Card>
				<Box sx={{display: "flex", alignItems: "flex-end", mt:-6}}>
					<Avatar src={ blogUser.current.urlAvatar } sx={{ border: "4px solid white", ml: 0.5, mr: 0.5, width: 100, height: 100 }} />
					<Typography variant="h5">{ blogUser.current.displayName }</Typography>
				</Box>
				<Divider sx={{mt:0.5, mb:0.5}}/>
				<Box sx={{display: "flex", gap:1}}>
					<Typography variant="h6" sx={{ width: "100%", alignSelf: "flex-end" }}>โพสต์</Typography>
					{isOwner[0] ? <Button variant="contained" color="success" onClick={ newPost }>New</Button> : null}
				</Box>
				<Divider sx={{mt:0.5, mb:0.5}}/>
				{(blogs[0].length > 0) ? <BlogList disableStatus={!isOwner[0]} blogs={blogs[0]} lUser={lUser[0]}/> : <Typography>ยังไม่มีนะ</Typography>}
				<Box sx={{mt:1}}/>
				<PageList pageNo={pageNo} pageNum={pageNum[0]} strRoute={"/user/"+userName}/>
			</Container>
		</div>
	);
	//
	return glob.render( isUInfoReady[ 0 ] ? <div>{ren}</div> : <div/> );
};

