
import React from "react";
import { Link, Typography, Container, Box, Divider } from "@mui/material";
import axios from "axios";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";
import BlogList from "./props_blog_list";
import PageList from "./props_page_list";

export default ( ) => {
	glob.init( );
	const isUInfoReady = React.useState( false );
	const blogs = React.useState( [ ] );
	const lUser = React.useState( { } );
	const pageNum = React.useState( 0 );
	const [ pageOffset, pageNo ] = glob.getPageData( );
	//
	const renderHeaderbar = widgetHeaderbar.init( );
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Recent" );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId === undefined ) { return; }
		const fetchData = async ( ) => {
			var res = ( await axios.post( "/backend/blogGetList", { listType: "recent", pageOffset: pageOffset } ) ).data;
			if( res.err ) { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: res.err } ); return;}
			blogs[1](res.blogs);
			lUser[1](res.lUser);
			pageNum[ 1 ]( res.pageNum );
			isUInfoReady[ 1 ]( true );
		};
		fetchData( );
	}, [ glob.userInfo[ 0 ] ] );
	//
	return glob.render( isUInfoReady[ 0 ] ? (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{mt:5, mb:8}}>
				<Divider sx={{mt:0.5, mb:0.5}}/>
				<Box>
					<Link href="/recent" underline="hover" color="inherit">
						<Typography variant="h6">บทความล่าสุด</Typography>
					</Link>
				</Box>
				<Divider sx={{mt:0.5}}/>
				<BlogList disableStatus blogs={blogs[0]} lUser={lUser[0]}/>
				<Box sx={{mt:1}}/>
				<PageList pageNo={pageNo} pageNum={pageNum[0]} strRoute={"/recent"}/>
			</Container>
		</div>
	) : <div/> );
};

