
import React from "react";
import { IconButton, Typography, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export const initWidgetCropImage = ( ) => {
	//const [ crop, setCrop ] = React.useState( { unit: "%", width: 30, aspect: 1.0 / 1.0 } );
	//
	var widget = { };
	widget.onClose = React.useRef( ( err, area, fileUrl ) => { } );
	widget.refSelectFile = undefined;
	widget.isOpen = React.useState( false );
	widget.dataImg = React.useRef( null );
	widget.title = React.useState( "Crop image" );
	widget.aspect = React.useState( 1.0 / 1.0 );
	widget.urlImage = React.useState( "" );
	widget.crop = React.useState( );
	widget.completedCrop = React.useState( null );
	widget.imgRef = React.useRef( null );
	//
	widget.startCrop = ( title, aspect ) => {
		widget.refSelectFile.click( );
		widget.title[ 1 ]( title );
		widget.aspect[ 1 ]( aspect );
	};
	//
	widget.onSelectedImg = ( event ) => {
		if( !event.target.files ) { return; }
		if( event.target.files.length <= 0 ) { return; }
		widget.dataImg.current = event.target.files[ 0 ];
		widget.urlImage[ 1 ]( URL.createObjectURL( widget.dataImg.current ) );
		widget.crop[ 1 ]( undefined );
		//console.log( widget.dataImg.current );
		widget.isOpen[ 1 ]( true );
/*		widget.onClose.current = ( area, url ) => {
			if( !url ) { return; }
			obj.urlImg.current = url;
			obj.cropArea.current = area;
			obj.onNewImage.current( );
		};
*/
	};
	//
	const handleCancelClose = ( ) => {
		widget.isOpen[ 1 ]( false );
		widget.onClose.current( null, null, null );
	};
	//
	const handleClose = async ( event, reason ) => {
		if( reason === "backdropClick" ) { handleCancelClose( ); return; }
		widget.isOpen[ 1 ]( false );
		const canvas = document.createElement( "canvas" );
		const ctx = canvas.getContext( "2d" );
		if( !ctx ) { widget.onClose.current( "No 2d context", null, null ); return; };
		//
		const image = widget.imgRef.current;
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const crop = widget.completedCrop[ 0 ];
		const area = { x: crop.x * scaleX, y: crop.y * scaleY, w: crop.width * scaleX, h: crop.height * scaleY };
		var fileUrl = null;
		//
		canvas.width = Math.floor( crop.width * pixelRatio * scaleX );
		canvas.height = Math.floor( crop.height * pixelRatio * scaleY );
		ctx.setTransform( pixelRatio, 0, 0, pixelRatio, 0, 0 );
		ctx.imageSmoothingQuality = "high";
		ctx.drawImage( image, area.x, area.y, area.w, area.h, 0, 0, crop.width * scaleX, crop.height * scaleY );
		await ( new Promise( async ( resolve, reject ) => {
			canvas.toBlob( ( blob ) => {
				if( !blob ) { return; } // error
				blob.name = "eiei";
				fileUrl = window.URL.createObjectURL( blob );
				//console.log( blob );
				//console.log( fileUrl );
				resolve( );
			}, "image/jpeg", 1 );
		} ) );
		//
		widget.onClose.current( null, area, fileUrl );
	};
	//
	const renTitle = (
		<DialogTitle sx={ { m: 0, p: 2 } }>
			{ widget.title[ 0 ] }
			<IconButton aria-label="close" onClick={ handleCancelClose } sx={{ position: "absolute", right: 8, top: 8, color: "myprim.dark", } } >
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
	//
	const centerAspectCrop = ( mediaWidth, mediaHeight, aspect ) => {
		return centerCrop( makeAspectCrop( { unit: '%', width: 100, }, aspect, mediaWidth, mediaHeight ), mediaWidth, mediaHeight, );
	}
	//
	widget.render = (
		<div>
			<input type="file" accept="image/*" style={ { display: "none" } } onChange={ widget.onSelectedImg } ref={ ( ref ) => { widget.refSelectFile = ref; } } />
			<Dialog onClose={ handleCancelClose } open={ widget.isOpen[ 0 ] }>
				{ renTitle }
				<DialogContent dividers>
					<ReactCrop
						crop={ widget.crop[ 0 ] }
						onChange={ ( _, percentCrop ) => widget.crop[ 1 ]( percentCrop ) }
						onComplete={ ( c ) => widget.completedCrop[ 1 ]( c ) }
						aspect={ widget.aspect[ 0 ] }
					>
						<img ref={ widget.imgRef } src={ widget.urlImage[ 0 ] } alt="ภาพ" onLoad={ ( e: React.SyntheticEvent<HTMLImageElement> ) => {
							const { width, height } = e.currentTarget;
							widget.crop[ 1 ]( centerAspectCrop( width, height, widget.aspect[ 0 ] ) );
						} } />
					</ReactCrop>
				</DialogContent>
				<DialogActions sx={{mb:1}}>
					<Button variant="contained" color="myprim" onClick={ handleCancelClose }>Cancel</Button>
					<Button variant="contained" color="myprim" onClick={ handleClose }>Ok</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
	return widget;
};

export const cropImgAddFormData = ( data, img, area ) => {
	data.append( "image", img );
	if( img ) { data.append( "x", area.x ); data.append( "y", area.y ); data.append( "w", area.w ); data.append( "h", area.h ); }
	else { data.append( "x", 0 ); data.append( "y", 0 ); data.append( "w", 0 ); data.append( "h", 0 ); }
};

