
var bused = { };

//bused.PERMISSION_HANDLE_BLOG = 0b1;
bused.PERMISSION_HANDLE_BLOG = 0;
bused.PERMISSION_XXXX = 1;

bused.hasPermission = ( value, permissionName ) => {
	return ( (value >> permissionName) & 1 );
}

module.exports = bused;

