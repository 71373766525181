
import React from "react";
import glob from "./glob";
import { Typography, Container, Box, Button, Grid } from "@mui/material";

export default ( ) => {
	glob.init( );
	var stateNa = React.useState( 0 );
	//
	const Test3Prop = ( props ) => {
		console.log( "3" );
		return (
			<Typography>ไร</Typography>
		)
	};
	//
	const TestProp = ( props ) => {
		var state1Na = React.useState( 0 );
		console.log( "1" );
		return (
			<Button onClick={ ( ) => { state1Na[ 1 ]( state1Na[ 0 ] + 1 ); } }>
				<Typography>แมว1</Typography>
				<Test3Prop aaa={state1Na[0]}/>
			</Button>
		)
	};
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "เทสอ่า" );
	}, [ glob.isReady ] );
	//
	return glob.render(
		<>
			<Button onClick={ ( ) => { stateNa[ 1 ]( stateNa[ 0 ] + 1 ); } }>
				<Typography>กด</Typography>
			</Button>
			<TestProp aaa={stateNa[0]}/>
		</>
	);
};

