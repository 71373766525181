
import React from "react";
import axios from "axios";
import { Box, Container, Typography, Grid, TextField, Link, Button } from "@mui/material";
import glob from "./glob"
import widgetHeaderbar from "./widget_headerbar";

export default ( ) => {
	glob.init( );
	const renderHeaderbar = widgetHeaderbar.init( );
	const [ emailName, setEmailName ] = React.useState( null );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Reset Password Email" );
		if( glob.userInfo[ 0 ].userId ) { setEmailName( glob.userInfo[ 0 ].email ); }
	}, [ glob.isReady ] );
	//
	const initMember = ( ) => {
		// submitPressed
		const submitPressed = async ( event ) => {
			event.preventDefault( );
			if( glob.state.current !== "idle" ) { return; }
			glob.state.current = "wait";
			var obj = { email: emailName };
			var res = await axios.post( "/backend/userResetPasswordEmail", obj );
			res = res.data;
			var snack = { };
			if( res.isSuccess ) { snack = { snackType: "success", snackMsg: "เช็ก email เพื่อดำเนินการต่อนะ" }; }
			else { snack = { snackType: "error", snackMsg: "ส่ง email ผิดพลาด" }; }
			glob.nextPage( [ "/", { state: snack } ] );
		};
		//
		return (
			<Container component="main" maxWidth="md">
				<form noValidate id="fromSubmit" onSubmit={ submitPressed }>
					<Box sx={ { mt: 8, display: "flex", flexDirection: "column", alignItems: "center" } }>
						<Typography>Email สำหรับ reset password จะถูกส่งไปที่ {emailName}</Typography>
						<p/>
						<Button variant="contained" color="myprim" type="submit" form="fromSubmit">ส่ง email</Button>
					</Box>
				</form>
			</Container>
		);
	};
	const renMember = initMember( );
	//
	const initGuest = ( ) => {
		const [ errEmail, setErrEmail ] = React.useState( "" );
		// submitPressed
		const submitPressed = async ( event ) => {
			event.preventDefault( );
			if( glob.state.current !== "idle" ) { return; }
			glob.state.current = "wait";
			var form = event.target;
			var obj = { email: form.elements[ "email" ].value };
			var res = await axios.post( "/backend/userResetPasswordEmail", obj );
			res = res.data;
			//
			var snack = { };
			if( res.isSuccess ) {
				glob.nextPage( [ "/", { state: { snackType: "success", snackMsg: "เช็ก email เพื่อดำเนินการต่อนะ" } } ] );
			}
			else {
				glob.state.current = "idle";
				setErrEmail( res.errEmail );
			}
		};
		//
		return (
			<Container component="main" maxWidth="xs">
				<Box sx={{mt: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<form noValidate id="fromSubmit" onSubmit={ submitPressed }>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography>Email ของสมาชิกที่จะ reset password:</Typography>
							</Grid>
							<Grid item xs={12} sx={{mt: 1}}>
								<TextField
									variant="outlined" required fullWidth id="email" label="Email Address" name="email" autoComplete="email"
									error={ errEmail !== "" }
									helperText={ errEmail }
									onChange={ ( event ) => { setErrEmail( "" ); } }
								/>
							</Grid>	
								<Grid item xs={12}>
								<Button variant="contained" color="myprim" type="submit" form="fromSubmit">ok</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Container>
		);
	};
	const renGuest = initGuest( );
	//
	const choice = ( glob.userInfo[ 0 ].userId ? ( <div>{renMember}</div> ) : ( <div>{renGuest}</div> ) );
	return glob.render(
		<div>
			{ renderHeaderbar }
			{ choice }
		</div>
	);
};

