
import React from "react";
import glob from "./glob";

export default ( ) => {
	glob.init( );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Not found" );
	}, [ glob.isReady ] );
	//
	return glob.render(
		<div>ไม่มีหน้านี้นะ</div>
	);
};

