
import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

var widget = { };

widget.init = ( ) => {
	widget.isOpen = React.useState( false );
	const handleClose = ( event, reason ) => { if( reason === "backdropClick" ) { return; } };
	//
	return (
		<Backdrop sx={{color: "#fff", zIndex: ( theme ) => theme.zIndex.drawer + 1}} open={widget.isOpen[ 0 ]} onClick={handleClose} >
			<CircularProgress color="inherit"/>
		</Backdrop>
	);
};

export default widget;

