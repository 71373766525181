
import React from "react";
import { Box, Typography, Paper, Grid, ButtonBase, IconButton, Link, Tooltip, CircularProgress } from "@mui/material";
import { Comment as CommentIcon, Visibility as VisibilityIcon, StickyNote2 as StickyNote2Icon } from "@mui/icons-material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import widgetConfirm from "./widget_confirm";
import widgetWaiting from "./widget_waiting";
import axios from "axios";
import glob from "./glob";
import bused from "bothused";

import BlogContent from "./props_blog_content";
import BlogComment from "./props_blog_comment";

export const addMoreBlogData = (blog, lUser) => {
	blog.blogLink = blog.status === "publish" ? "/post/" + blog.blogId : null;
	blog.strDate = blog.status === "publish" ? glob.getStrDate(blog.datePublished) : null;
	blog.urlCover = blog.hasCover ? "/assets/blog/" + blog.blogId + "/cover.webp" : null;
	blog.user = lUser[blog.userId];
	return blog;
};

const BlogHeader = ( props ) => {
	const imgSize = 100;
	var { blog, disableStatus, onDeleted, onExpand } = props;
	//const testTitle = "สวัสดีครับ เราแค่จัดเลี้ยงเล็ก ๆ กันที่ร้านกาแฟน่ารัก ๆ แถวทองหล่อ และเชิญเฉพาะคนที่เกี่ยวข้องไม่กี่คนเข้าร่วม ฮ่า ๆ ๆ.. รู้นะว่าคุณกำลังคิดอะไรอยู่?! ใช่แล้วครับ..";
	//const testTitle = "There was traffic clogging up I-95. It was a good thing I gave myself plenty of time for the errand. I’d be home late but not terribly so and Susan would still be awake";
	var canEdit = false;
	if( glob.userInfo[ 0 ].userId === blog.userId ) { canEdit = true; }
	if( bused.hasPermission( glob.userInfo[ 0 ].permissions, bused.PERMISSION_HANDLE_BLOG ) ) { canEdit = true; }
	//
	return (
		<Paper variant="outlined" square sx={{mt:0.5, p:0.5}}>
			<Grid container spacing={0.5}>
				<Grid item>
					{ blog.urlCover ? (
						<IconButton disableRipple disabled={blog.status !== "publish"} sx={{ width: imgSize, height: imgSize }} onClick={ onExpand }>
							<Box component="img" sx={{ width: imgSize, height: imgSize }} alt="ภาพ" src={ blog.urlCover } />
						</IconButton>
					) : (
						<Box sx={{ height: imgSize }} />
					) }
				</Grid>
				<Grid item xs container direction="column">
					<Grid item xs>
						<ButtonBase disableRipple disabled={blog.status !== "publish"} onClick={ onExpand }>
							<Typography align="justify">{ blog.title }</Typography>
						</ButtonBase>			
					</Grid>
					<Grid item container sx={{display: "flex", alignItems: "flex-end", gap:0.5}}>
						{ blog.user ? (
							<Link href={"/user/" + blog.user.userName} underline="hover" color="mytheme">
								<Typography variant="body2">{blog.user.displayName}</Typography>
							</Link>
						) : null }
						{ blog.strDate ?
							<Link href={blog.blogLink} variant="body2"  underline="none" color="inherit" sx={ [ ( theme ) => ( { "&:hover": { color: "myprim.dark", }, } ) ] }>{ blog.strDate }</Link>
						: null }
						<VisibilityIcon fontSize="small" sx={{ color: "myprim.main", }}/>
						<Typography color="myprim.dark" variant="body2" sx={{mr:0.5}}>{blog.viewAllTime}</Typography>
						<CommentIcon fontSize="small" sx={{ color: "myprim.main", }}/>
						<Typography color="myprim.dark" variant="body2" sx={{mr:0.5}}>{blog.commentNum}</Typography>
						{disableStatus ? null: (
							<Box sx={{display: "flex", alignItems: "flex-end"}}>
								<StickyNote2Icon fontSize="small" sx={{ color: "myprim.main", }}/>
								<Typography color="myprim.dark" variant="body2" sx={{mr:1}}>{blog.status}</Typography>
							</Box>
						)}
						{ canEdit ? (
							<Box sx={{display: "flex", alignItems: "flex-end"}}>
								<Tooltip title="ลบ">
									<IconButton size="small" sx={{display: "flex", alignItems: "flex-end", px:0, py:0, color: "myprim.main", "&:hover":{color:"myprim.dark"},}} disableRipple={true} onClick={ async ( ) => {
										if( glob.state.current !== "idle" ) { return; }
										widgetConfirm.title[ 1 ]( "ลบบทความ" );
										widgetConfirm.content[ 1 ]( "บทความ “" + blog.title + "” จะถูกลบออกถาวร");
										widgetConfirm.isOpen[ 1 ]( true );
										widgetConfirm.onClose.current = async ( isConfirm ) => {
											if( isConfirm ) {
												widgetWaiting.isOpen[ 1 ]( true );
												var src = { userId: glob.userInfo[ 0 ].userId, blogId: blog.blogId };
												var ret = ( await axios.post( "/backend/blogDelete", src ) ).data;
												widgetWaiting.isOpen[ 1 ]( false );
												onDeleted( ret.err );
											}
										};
									}}>
										<DeleteIcon/>
									</IconButton>
								</Tooltip>
								<Tooltip title="แก้ไข">
									<Link href={"/edit/"+blog.blogId} underline="none" color="inherit" sx={ [ {display: "flex", alignItems: "flex-end", color: "myprim.main"}, ( theme ) => ( { "&:hover": { color: "myprim.dark", }, } ) ] }>
										<EditIcon/>
									</Link>
								</Tooltip>
							</Box>
						) : null }
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ( props ) => {
	var { blog, disableStatus, alwaysExpand, onDeleted } = props;
	//const widgets = React.useState( [ {renType: "circular"} ] );
	const widgets = React.useState( [ ] );
	//
	const isShowContent = React.useRef( false );
	const refOps = React.useRef( null );
	const refHeader = React.useRef( null );
	//
	const fetchOps = async ( ) => {
		widgets[ 1 ]( [ {renType: "circular"} ] );
		var res = ( await axios.post( "/backend/blogGetContent", { userId: glob.userInfo[ 0 ].userId, blogId: blog.blogId } ) ).data;
		if( res.err ) {
			glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: res.err } );
			refOps.current = [ ];
			refHeader.current = { };
		} else {
			refOps.current = JSON.parse( res.ops );
			refHeader.current = res.header;
		}
	};
	//
	const onExpand = async ( ) => {
		if( alwaysExpand ) { return; }
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		isShowContent.current = !isShowContent.current;
		if( isShowContent.current ) {
			if( !refOps.current ) {
				await fetchOps( );
			}
			widgets[ 1 ]( [ {renType: "content"} ] );
		}
		else {
			widgets[ 1 ]( [ ] );
		}
//		console.log( "^3^" );
		glob.state.current = "idle";
	};
	//
	React.useEffect( ( ) => {
		if( !alwaysExpand ) { return; }
		const fetchData = async ( ) => {
			await fetchOps( );
			widgets[ 1 ]( [ {renType: "content"} ] );
		};
		fetchData( );
	}, [ alwaysExpand ] );
	//
	const renWgs = ( wg, index ) => {
		switch( wg.renType ) {
			case "circular": {
				return(
					<Box key={index} sx={{display: "flex", justifyContent: "center", mt:1}}>
						<CircularProgress />
					</Box>
				);
				break;
			};
			case "content": {
				return(
					<Box key={index}>
						<BlogContent ops={refOps.current} lastUpdated={blog.lastUpdated} blogId={blog.blogId}/>
						<BlogComment canComment={blog.canComment} canGuestComment={blog.canGuestComment} authorId={blog.userId} blogId={blog.blogId} header={refHeader.current}/>
					</Box>
				);
				break;
			};
		};
	};
//	await wgShowComments.init( glob.userInfo[ 0 ].userId, blog.userId, blogId, resContent.header );
	//
	return (
		<>
			<BlogHeader blog={blog} disableStatus={disableStatus} onDeleted={onDeleted} onExpand={onExpand}/>
			{ widgets[ 0 ].map( renWgs ) }
		</>
	);
};

