
import React from "react";
import { IconButton, Typography, Button } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

var widget = { };

widget.init = ( ) => {
	widget.isOpen = React.useState( false );
	widget.title = React.useState( "Confirm" );
	widget.content = React.useState( "ยืนยัน" );
	widget.onClose = React.useRef( async ( isConfirm ) => { } );
	//
	const handleCancelClose = async ( ) => {
		widget.isOpen[ 1 ]( false );
		await widget.onClose.current( false );
	};
	//
	const handleClose = async ( event, reason ) => {
		if( reason === "backdropClick" ) { await handleCancelClose( ); return; }
		widget.isOpen[ 1 ]( false );
		await widget.onClose.current( true );
	};
	//
	const renTitle = (
		<DialogTitle sx={ { m: 0, p: 2 } }>
			{ widget.title[ 0 ] }
			<IconButton aria-label="close" onClick={ handleCancelClose } sx={{ position: "absolute", right: 8, top: 8, color: "myprim.dark", } } >
				<CloseIcon />
			</IconButton>
		</DialogTitle>
	);
	//
	return (
		<Dialog onClose={ handleCancelClose } open={ widget.isOpen[ 0 ] }>
			{/* renTitle */}
			<DialogTitle>{widget.title[ 0 ]}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					{/*<Typography>{ widget.content[ 0 ] }</Typography>*/}
					{ widget.content[ 0 ] }
				</DialogContentText>
			</DialogContent>
			<DialogActions sx={{mb:1}}>
				<Button variant="contained" color="myprim" onClick={ handleCancelClose }>Cancel</Button>
				<Button variant="contained" color="myprim" onClick={ handleClose }>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

export default widget;

