
import React from "react";
import axios from "axios";
import { Box, Container, Typography, Grid, TextField, Avatar, Link, Button } from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";

export default ( ) => {
	glob.init( );
	const renderHeaderbar = widgetHeaderbar.init( );
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Signup Email" );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId ) { glob.nextPage( [ "/", { } ] ); }
	}, [ glob.userInfo[ 0 ] ] );
	//
	const [ errEmail, setErrEmail ] = React.useState( "" );
	const submitPressed = async ( event ) => {
		event.preventDefault( );
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var form = event.target;
		var obj = { };
		obj.email = form.elements[ "email" ].value;
		//
		await axios.post( "/backend/userSignUpEmail", obj ).then( ( res ) => {
			res = res.data;	
			if( res.isSuccess ) {
				glob.nextPage( [ "/nowhere", { state: { msg: "เช็ก email เพื่อดำเนินการต่อนะ (หากท่านไม่พบก็ลองเช็กที่จดหมายขยะดู)" } } ] );
			}
			else if( res.errorOther !== "" ) {
				glob.nextPage( [ "/", { state: { snackType: "error", snackMsg: res.errorOther } } ] );
			}
			else {
				setErrEmail( res.errorEmail );
				glob.state.current = "idle";
			}
		} ).catch( ( error ) => {
			glob.nextPage( [ "/", { state: { snackType: "error", snackMsg: "Fetch error." } } ] );
		} );
		//
		glob.state.current = "idle";
	};
	//
	return glob.render( ( glob.userInfo[ 0 ].userId ? ( <div/> ) :
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="xs">
				<Box sx={{mt: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<Box sx={{display: "flex", alignItems: "baseline"}}>
						<Avatar sx={{mr: 1, bgcolor: "mytheme"}}>
							<EmailIcon/>
						</Avatar>
						<Typography component="h1" variant="h4">Sign up</Typography>
					</Box>
					<form noValidate id="fromSignup" onSubmit={ submitPressed }>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{mt:4}}>
								<Typography>Email ที่ใช้สมัครสมาชิก:</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined" required fullWidth id="email" label="Email Address" name="email" autoComplete="email"
									error={ errEmail !== "" }
									helperText={ errEmail }
									onChange={ ( event ) => { setErrEmail( "" ); } }
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<Button fullWidth variant="contained" color="myprim" type="submit" form="fromSignup">ok</Button>
							</Grid>
							<Grid item xs={12} sm={8} sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
								<Typography>หรือ</Typography>
								<Link href="/login">Log in</Link>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Container>
		</div>
	) );
};

