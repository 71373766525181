
import React from "react";
import { Card, CardMedia, Box, Button, Toolbar, AppBar } from "@mui/material";
import widgetUserAvatar from "./widget_user_avatar";

var widgetHeaderbar = { };

widgetHeaderbar.init = ( ) => {
	const renderUserAvatar = widgetUserAvatar.init( );
	//
	return (
		<React.Fragment>
			<AppBar sx={ { bgcolor: "background.paper" } }>
				<Toolbar variant="dense">
					<Card elevation={0} sx={ { maxWidth: 128 } }>
						<Button href="/">
							<CardMedia component="img" image="/assets/default/logo.png" alt="โลโก้อ่า" />
						</Button>
					</Card>
					<Box sx={ { flexGrow: 1 } } />
					{ renderUserAvatar }
				</Toolbar>
			</AppBar>
			<Toolbar />
		</React.Fragment>
	);
};

export default widgetHeaderbar;
