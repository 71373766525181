
import React from "react";
import { Box, TextField, Divider, Container, Badge, IconButton, Typography, Avatar, Input, Button, Card, CardMedia, FormControlLabel, Checkbox, Tabs, Tab } from "@mui/material";
import { CameraAltOutlined as CameraAltOutlinedIcon, Settings as SettingsIcon } from "@mui/icons-material";
import axios from "axios";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";
import { initWidgetCropImage, cropImgAddFormData } from "./widget_crop_image";
import widgetWaiting from "./widget_waiting";
//import imageExists from "image-exists";

export default ( ) => {
	glob.init( );
	const renderHeaderbar = widgetHeaderbar.init( );
	const wgCrop = initWidgetCropImage( );
	const tabValue = React.useState( 0 );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		glob.setTitle( "Profile settings" );
	}, [ glob.isReady ] );
	//
	const getRenCardImg = ( img ) => {
		return (
			<Card variant="outlined" sx={{ maxWidth: "100%", border: 0, borderRadius: 0 }}>
				<CardMedia component="img" image={ img } alt="ภาพ" />
			</Card>
		);
	};
	const getRenAvatarImg = ( img ) => { return ( <Avatar src={ img } sx={{ width: 80, height: 80 }} /> ); };
	//
	const initWidgetImage = ( strTitle, strCropTitle, sx, aspect, fnRenImg, name ) => {
		var obj = { };
		obj.title = React.useState( strTitle );
		//obj.cropTitle = React.useState( strCropTitle );
		obj.sx = React.useState( sx );
		obj.urlImg = React.useState( null );
		obj.renImg = fnRenImg( obj.urlImg[ 0 ] );
		obj.urlOldImg = React.useRef( null );
		obj.isShowBt = React.useState( false );
		//obj.newImg = React.useRef( null );
		obj.cropData = React.useRef( { } );
		//obj.crop = React.useRef( null );		
		//
		obj.onClickSelect = ( event ) => {
			if( glob.state.current !== "idle" ) { return; }
			wgCrop.startCrop( strCropTitle, aspect );
			wgCrop.onClose.current = ( err, area, fileUrl ) => {
				if( err ) { glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: err } ); return; }
				if( !fileUrl ) { return };
				obj.cropData.current.area = area;
				obj.cropData.current.img = wgCrop.dataImg.current;
				obj.urlImg[ 1 ]( fileUrl );
				obj.isShowBt[ 1 ]( true );
			};
		};
		//
		obj.cancelImg = async ( event ) => {
			if( glob.state.current !== "idle" ) { return; }
			glob.state.current = "wait";
			obj.urlImg[ 1 ]( obj.urlOldImg.current );
			obj.isShowBt[ 1 ]( false );
			glob.state.current = "idle";
		}
		//
		obj.submitImg = async ( event ) => {
			if( glob.state.current !== "idle" ) { return; }
			glob.state.current = "wait";
			widgetWaiting.isOpen[ 1 ]( true );
			//
			const onUploadProgress = ( event ) => { };
			var data = new FormData( );
			cropImgAddFormData( data, obj.cropData.current.img, obj.cropData.current.area );
			//
			var ret = ( await axios.post( "/backend/userUpdate" + name, data, {
				headers: { "Content-Type": "multipart/form-data", }, onUploadProgress,
			} ) ).data;
			// Loading, snackMsg
			if( ret.err ) {
				obj.urlImg[ 1 ]( obj.urlOldImg.current );
				glob.refreshSnack[ 1 ]( { snackType: "error", snackMsg: "Upload ผิดพลาด" } );
			}
			else {
				var info = { ...glob.userInfo[ 0 ] };
				info[ "url" + name ] = obj.urlImg[ 0 ];
				glob.userInfo[ 1 ]( info );
				glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "Upload เรียบร้อยแล้ว" } );
			}
			//
			obj.isShowBt[ 1 ]( false );
			widgetWaiting.isOpen[ 1 ]( false );
			glob.state.current = "idle";
		};
		//
		obj.render = (
			<div>
				<Box sx={{mt:1}}>
					<Typography variant="h6">{ obj.title[ 0 ] }</Typography>
					<Badge overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} sx={ obj.sx[ 0 ] } badgeContent={
						<IconButton onClick={ obj.onClickSelect } size="small" sx={ [ { bgcolor: "myprim.light" }, ( theme ) => ( { "&:hover": { bgcolor: "myprim.main", }, } ), ] }>
							<CameraAltOutlinedIcon/>
						</IconButton>
					} >
						{ obj.renImg }
					</Badge>
				</Box>
				{ obj.isShowBt[ 0 ] ?  (
					<Box sx={{display: "flex", mt:1, gap:1}}>
						<Button variant="contained" color="myprim" onClick={ obj.cancelImg }>Cancel</Button>
						<Button variant="contained" color="myprim" onClick={ obj.submitImg }>Upload</Button>
					</Box>
				) : null }
			</div>
		);
		return obj;
	}
	//
	const wgCover = initWidgetImage( "รูปหน้าปก", "ตัดแต่งรูปหน้าปก", { display: "block"}, 3.0 / 1.0, getRenCardImg, "Cover" );
	const wgAvatar = initWidgetImage( "รูปโปรไฟล์", "ตัดแต่งรูปโปรไฟล์", { }, 1.0 / 1.0, getRenAvatarImg, "Avatar" );
	//
	const displayName = React.useRef( null );
	const canComment = React.useState( true );
	const canGuestComment = React.useState( true );
	const errDisplayName = React.useState( "" );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId ) {
			var info = glob.userInfo[ 0 ];
			//
			displayName.current.value = info.displayName;
			// cover
			wgCover.urlOldImg.current = info.urlCover;
			if( !wgCover.urlImg[ 0 ] ) { wgCover.urlImg[ 1 ]( info.urlCover ); }
			// avatar
			wgAvatar.urlOldImg.current = info.urlAvatar;
			if( !wgAvatar.urlImg[ 0 ] ) { wgAvatar.urlImg[ 1 ]( info.urlAvatar ); }
			//
			canComment[ 1 ]( info.canComment ? true : false );
			canGuestComment[ 1 ]( info.canGuestComment ? true : false );
		}
		else if( glob.userInfo[ 0 ].userId === null ) { glob.nextPage( [ "/login?redirect="+glob.url64, { } ] ); }
	}, [ glob.userInfo[ 0 ] ] );
	//
	const submitPressed = async ( event ) => {
		event.preventDefault( );
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var form = event.target;
		var obj = { };
		obj.userId = glob.userInfo[ 0 ].userId;
		obj.displayName = displayName.current.value;
		obj.canComment = canComment[ 0 ];
		obj.canGuestComment = canGuestComment[ 0 ];
		//
		var res = ( await axios.post( "/backend/userSettingProfile", obj ) ).data;
		if( res.isSuccess ) {
			glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "Save สำเร็จ" } );
			glob.state.current = "idle";
		} else if( res.errOther !== "" ) {
			glob.nextPage( [ "/", { state: { snackType: "error", snackMsg: res.errOther } } ] );
		} else {
			errDisplayName[ 1 ]( res.errDisplayName );
			glob.state.current = "idle";
		}
	};
	//
	const ren = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{mt:5, mb:5}}>
				<Box sx={{mb: 4, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<Box sx={{display: "flex", alignItems: "baseline"}}>
						<Avatar sx={{mr: 1, bgcolor: "mytheme"}}><SettingsIcon/></Avatar>
						<Typography component="h1" variant="h4">Settings</Typography>
					</Box>
				</Box>
				<Tabs value={tabValue[0]} onChange={ ( event, value ) => { tabValue[ 1 ]( value ); } } sx={{mb:5}}>
					<Tab label="Profile" component="a" href="/setting_profile" onClick={ ( event, mouseEvent ) => event.preventDefault( ) } />
					<Tab label="Account" component="a" href="/setting_account" />
				</Tabs>
				<form noValidate id="fromSettings" onSubmit={submitPressed}>
					<TextField variant="outlined" name="name" fullWidth label="ชื่อที่แสดง" id="displayName"
						inputRef={displayName}
						error={ errDisplayName[ 0 ] !== "" }
						helperText={ errDisplayName[ 0 ] }
						onChange={ ( event ) => { errDisplayName[ 1 ]( "" ); } }
						sx={{mb: 1}}
					/>
					<Typography>ค่าเริ่มต้น</Typography>
					<Box sx={{ display: "flex", flexDirection: "column", pl: 2, mt:0.5, mb: 1}}>
						<div><FormControlLabel control={<Checkbox color="default" checked={canComment[0]} onChange = { ( event ) => {
							canComment[ 1 ]( event.target.checked );
						}} />} label="เปิดคอมเม้นต์" /></div>
						<div><FormControlLabel control={<Checkbox color="default" checked={canGuestComment[0]} onChange = { ( event ) => {
							canGuestComment[ 1 ]( event.target.checked );
						}} />} label="Guest คอมเม้นต์ได้" /></div>
					</Box>
					<Button variant="contained" color="myprim" type="submit" form="fromSettings">Save</Button>
				</form>
				<Box sx={{mt:2, mb: 4}}> <Divider/> </Box>
				{ wgCover.render }
				<Box sx={{mt:2, mb: 4}}> <Divider/> </Box>
				{ wgAvatar.render }
			</Container>
			{ wgCrop.render }
		</div>
	);
	//
	return glob.render( glob.userInfo[ 0 ].userId ? <div>{ren}</div> : <div/> );
};

