
import React from "react";
//import { useLocation } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Container, Typography, Grid, TextField, Avatar, Link, Input, Button } from "@mui/material";
import { Login as LoginIcon } from "@mui/icons-material";
import widgetHeaderbar from "./widget_headerbar";
import base64url from "base64url";
import glob from "./glob";

export default ( ) => {
	glob.init( );
//	const location = useLocation( );
//	const navigate = useNavigate( );
	const renderHeaderbar = widgetHeaderbar.init( );
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; }
		glob.setTitle( "Login" );
//		console.log( location );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId ) {
			glob.nextPage( [ "/", { } ] );
		}
	}, [ glob.userInfo[ 0 ] ] );
	//
	const [ errUserName, setErrUserName ] = React.useState( "" );
	const [ errPassword, setErrPassword ] = React.useState( "" );
	const [ errOther, setErrOther ] = React.useState( "" );
	//
	const submitPressed = async ( event ) => {
		event.preventDefault( );
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var form = event.target;
		var obj = { };
		obj.userName = form.elements[ "userName" ].value;
		obj.password = form.elements[ "password" ].value;
		//
		var res = await axios.post( "/backend/userLogin", obj );
		res = res.data;
		//
		if( res.isSuccess ) {
			var url = glob.urlSearchParams.get( "redirect" );
			url = url ? base64url.decode( url ) : null;
			glob.nextPage( [ url ? url : "/", { state: { snackType: "success", snackMsg: "Login สำเร็จแล้ว" } } ] );
		}
		else {
			setErrUserName( res.errUserName );
			setErrPassword( res.errPassword );
			setErrOther( res.errorOther );
			glob.state.current = "idle";
		}
	};
	//
	var renLogin = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="xs">
				<Box sx={{mt: 8, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<Box sx={{display: "flex", alignItems: "baseline"}}>
						<Avatar sx={{mr: 1, bgcolor: "mytheme"}}>
							<LoginIcon/>
						</Avatar>
						<Typography component="h1" variant="h4">Log in</Typography>
					</Box>
					<Box sx={{mt:2}}>
						<form noValidate id="fromLogin" onSubmit={submitPressed}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField autoComplete="uname" name="userName" variant="outlined" required fullWidth id="userName" label="UserName"
										error={ errUserName !== "" }
										helperText={ errUserName }
										onChange={ ( event ) => {
											setErrUserName( "" );
											setErrOther( "" );
										} }
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField variant="outlined" required fullWidth name="password" label="Password" type="password" id="password"
										autoComplete="current-password"
										error={ errPassword !== "" }
										helperText={ errPassword }
										onChange={ ( event ) => {
											setErrPassword( "" );
											setErrOther( "" );
										} }
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="body2" color="error">{errOther}</Typography>
								</Grid>
								<Grid item xs={12} sm={4}>
									<Button fullWidth variant="contained" color="myprim" type="submit" form="fromLogin" >Log In</Button>
								</Grid>
								<Grid item xs={12} sm={8} sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
									<Typography>หรือ</Typography>
									<Link href="/reset_password_email">ลืม password</Link>
									<Typography>|</Typography>
									<Link href="/signup_email">Register</Link>
								</Grid>
							</Grid>
						</form>
					</Box>
				</Box>
			</Container>
		</div>
	);
	//
	return glob.render(
		glob.userInfo[ 0 ].userId ? ( <div/> ) : ( <div>{ renLogin }</div> )
	);
};

