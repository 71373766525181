
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";

import PageNotfound from "./page_notfound";
import PageHome from "./page_home";
import PageNowhere from "./page_nowhere";
import PageUserSignUpEmail from "./page_user_signup_email";
import PageUserRegister from "./page_user_register";
import PageUserLogin from "./page_user_login";
import PageUserResetPasswordEmail from "./page_user_reset_password_email";
import PageUserResetPassword from "./page_user_reset_password";
import PageSettingAccount from "./page_setting_account";
import PageSettingProfile from "./page_setting_profile";
import PageBlogUser from "./page_blog_user";
import PageBlogEdit from "./page_blog_edit";
import PageBlogPost from "./page_blog_post";
import PageRecent from "./page_recent";
import PageTest from "./page_test";

const Root = ( ) => {
	return(
		<StyledEngineProvider injectFirst>
			<CssBaseline/>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={ <PageHome/> } />
					<Route path="/nowhere" element={ <PageNowhere/> }/>
					<Route path="/signup_email" element={ <PageUserSignUpEmail/> }/>
					<Route path="/register/:id/:passRaw" element={ <PageUserRegister/> }/>
					<Route path="/login" element={ <PageUserLogin/> }/>
					<Route path="/reset_password_email" element={ <PageUserResetPasswordEmail/> }/>
					<Route path="/reset_password/:id/:passRaw" element={ <PageUserResetPassword/> }/>
					<Route path="/setting_account" element={ <PageSettingAccount/> }/>
					<Route path="/setting_profile" element={ <PageSettingProfile/> }/>
					<Route path="/user/:id" element={ <PageBlogUser/> }/>
					<Route path="/edit/:id" element={ <PageBlogEdit/> }/>
					<Route path="/post/:id" element={ <PageBlogPost/> }/>
					<Route path="/recent" element={ <PageRecent/> }/>
					<Route path="/test" element={ <PageTest/> }/>
					<Route path="*" element={ <PageNotfound/> }/>
				</Routes>
			</BrowserRouter>
		</StyledEngineProvider>
	);
};

const root = ReactDOM.createRoot( document.getElementById( "root" ) );
root.render( <Root /> );
