
import React from "react";
import { Box, Avatar, Typography, IconButton, Link, Button, TextField, CircularProgress, Tooltip, Divider } from "@mui/material";
import { ArrowRight as ArrowRightIcon, ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Edit as EditIcon, Delete as DeleteIcon, Flag as FlagIcon, InsertEmoticon as InsertEmoticonIcon } from "@mui/icons-material";
import axios from "axios";
import Autolinker from "autolinker";
import glob from "./glob";
import widgetConfirm from "./widget_confirm";
import widgetWaiting from "./widget_waiting";
import bused from "bothused";

const FragmentComment = 50;
const FragmentReply = 50;

const NewComment = ( props ) => {
	const textMax = 10000;
	var { disableShow, alwaysShowBt, dfText, onCommented, onCancelled } = props;
	var urlAvatar = glob.userInfo[ 0 ].userId ? glob.userInfo[ 0 ].urlAvatar : null;
	const textDefault = React.useState( dfText ? dfText : "" );
	const isShrinkLabel = React.useState( dfText ? true : false );
	const label = React.useState( "ใส่ความคิดเห็น" );
	const isError = React.useState( false );
	const isShowButton = React.useState( false );
	var refText = React.useRef( null );
	onCommented = onCommented ? onCommented : async ( text ) => { };
	onCancelled = onCancelled ? onCancelled : async ( ) => { };
	//
	const clearThings = ( ) => {
		textDefault[ 1 ]( "" );
		refText.current.value = "";
		isShrinkLabel[ 1 ]( false );
		label[ 1 ]( "ใส่ความคิดเห็น" );
		isError[ 1 ]( false );
		isShowButton[ 1 ]( false );
	};
	//
	const onCommentClicked = async ( ) => {
		var textLength = refText.current.value.length;
		if( textLength > textMax ) {
			label[ 1 ]( "ความเห็นยาวเกินไปนะ (" + textLength + "/" + textMax + ")" );
			isError[ 1 ]( true );
		} else {
			textDefault[ 1 ]( "" );
			await onCommented( refText.current.value );
			clearThings( );
		}
	};
	//
	const onCancelClicked = ( ) => {
		clearThings( );
		onCancelled( );
	};
	//
	const ren = (
		<Box sx={{display: "flex", gap:2}}>
			<Avatar sx={{mt: 3,}} src={ urlAvatar } />
			<Box sx={{width: 1}}>
				<TextField sx={{mb:1}} fullWidth multiline id="comment" variant="standard" label={label[0]} InputLabelProps={{ shrink: isShrinkLabel[ 0 ] }}
					inputRef={refText}
					error={isError[ 0 ]}
					defaultValue={textDefault[ 0 ]}
					onFocus={ ( event ) => {
						isShrinkLabel[ 1 ]( true );
						isShowButton[ 1 ]( true );
						isError[ 1 ]( false );
						label[ 1 ]( "ใส่ความคิดเห็น" );
					} }
					onBlur={ ( event ) => {
						if( refText.current.value.length <= 0 ) { isShrinkLabel[ 1 ]( false ); }
					} }
				/>
				{ ( isShowButton[ 0 ] || alwaysShowBt ) ? (
					<Box sx={{display: "flex", justifyContent: "flex-end", gap: 1}}>
						<Button color="mydark" onClick={ onCancelClicked }>Cancel</Button>
						<Button variant="contained" color="myprim" onClick={ onCommentClicked }>Comment</Button>
					</Box>
				) : null }
			</Box>
		</Box>
	);
	//
	return ( disableShow ? null : ren );
};

export default (props) => {
	var { canComment, canGuestComment, authorId, blogId, header } = props;
	const widgets = React.useState( [ ] );
	var userId = glob.userInfo[ 0 ].userId ? glob.userInfo[ 0 ].userId : null;
	var hasUserPer = bused.hasPermission( glob.userInfo[ 0 ].permissions, bused.PERMISSION_HANDLE_BLOG );
	//
	const addList = async ( mhs, offset, num ) => {
		if( mhs.length <= 0 ) {return;}
		// safe offset & num
		offset = glob.clamp( offset, 0, mhs.length - 1 );
		num = glob.clamp( num, 0, mhs.length );
		if( (offset + num) > mhs.length ) { num = mhs.length - offset; }
		//
		// newCommentIds
		var newCommentIds = [ ];
		for( var i=offset; i<(offset+num); i++ ) { const commentId = mhs[ i ].commentId; newCommentIds.push( commentId ); }
		if( newCommentIds.length <= 0 ) { return; }
		//
		var src = { };
		src.userId = userId;
		src.blogId = blogId;
		src.commentIds = newCommentIds;
		var ret = ( await axios.post( "/backend/blogPostGetCommentData", src ) ).data;
		var mentDatas = ret.datas ? ret.datas : [ ];
		for( var i=0; i<mentDatas.length; i++ ) { mentDatas[ i ] = JSON.parse( mentDatas[ i ] ); } // json to obj
		for( var i=0; i<mentDatas.length; i++ ) { mhs[ offset+i ].mentData = mentDatas[ i ]; } // mhs
		// newUserIds
		var newUserIds = [ ];
		for( var i=0; i<mentDatas.length; i++ ) {
			const push = ( id ) => {
				if( !id ) { return; }
				if( header.lUser[ id ] ) { return; }
				newUserIds.push( id );
				header.lUser[ id ] = { };
			};
			push( mentDatas[ i ].userId );
			push( mentDatas[ i ].repliedUserId );
		}
		if( newUserIds.length <= 0 ) { return; }
		//
		var src = { };
		src.userIds = newUserIds;
		var ret = ( await axios.post( "/backend/userGetInfosLv1", src ) ).data;
		var userDatas = ret.datas ? ret.datas : [ ];
		 // header.lUser
		for( var i=0; i<userDatas.length; i++ ) {
			var userData = userDatas[ i ];
			userData.hasAvatar ? userData.urlAvatar = "/assets/user/" + newUserIds[ i ] + "/avatar.webp" : userData.urlAvatar = "/assets/default/avatar.webp";
			delete userData.hasAvatar;
			header.lUser[ newUserIds[ i ] ] = userData;
		}
	};
	//
	const markCommentWaitingLoaded = ( maxComment, maxReply ) => {
		const mark = ( mhs, max ) => {
			const getNum = ( offset ) => {
				var n = 0;
				for( var i=offset; i<mhs.length; i++ ) {
					if( mhs[ i ].mentData ) { return n; }
					if( n >= max ) { return n; }
					n++;
				}
				return n;
			};
			//
			var i = 0;
			while( i < mhs.length ) {
				var n = getNum( i );
				if( n > 0 ) { mhs[ i ].op = { status: "waitingLoad", num: n, }; i += n; }
				else { i++; }
			}
		}
		//
		const mHeaders = header.comments;
		mark( mHeaders, maxComment );
		for( var i=0; i<mHeaders.length; i++ ) { mark( mHeaders[ i ].repiles, maxReply ); };
	};
	//
	const setWgs = ( ) => {
		const mhs = header.comments;
		var wgs = [ ], i = 0;
		//
		const push = ( offset, mh, parent ) => {
			var wg = { mh: mh, offset: offset };
			var isReply = false;
			if( mh.op ) {
				if( mh.op.status === "waitingLoad" ) {
					wg.renType = "moreComment";
					wg.mhs = parent ? parent.repiles : mhs;
					offset += mh.op.num;
				} else if( mh.op.status === "loadingComment" ) {
					wg.renType = "circular";
					offset += mh.op.num;
				} else if( mh.op.status === "editing" ) {
					wg.parent = parent;
					wg.renType = "editing";
					offset++;
				} else { wg.renType = "none"; offset++; }
			} else {
				wg.renType = "comment";
				wg.parent = parent;
				offset++;
				if( mh.isShowReply ) { isReply = true; /*retReps = mh.repiles;*/ }
			}
			//console.log( wgs );
			wgs.push( wg );
			return [ offset, isReply ];
		};
		//
		while( i < mhs.length ) {
			var isReply = false;
			var mh = mhs[ i ];
			[ i, isReply ] = push( i, mh, null );
			if( isReply ) {
				var j = 0, temp = null;
				while( j < mh.repiles.length ) { [ j, temp ] = push( j, mh.repiles[ j ], mh ); }
				//console.log( repiles );
			}
		}
		widgets[ 1 ]( wgs );
		//console.log( mhs );
	};
	//
	const getRenWg = ( wg, index ) => {
		//
		const ShowComment = ( props ) => {
			var { mh, parent } = wg;
			var mData = mh.mentData;
			var urlAvatar = null;
			var urlUser = null;
			var displayName = "";
			var strDateCreated = glob.getStrDate( mData.dateCreated ) + ( mData.isEdited ? " (ถูกแก้ไข)" : "" );
			var strReply = mh.isShowReply ? "ซ่อนการตอบกลับ " : "ดูการตอบกลับ ";
			var repNumIcon = mh.isShowReply ? <ArrowDropDownIcon/> : <ArrowRightIcon/>;
			var strRepNum = ( mh.repiles.length > 0 ) ? ( strReply + mh.repiles.length + " ความเห็น" ) : null;
			var marginY = parent ? 2 : 3;
			var avatarSize = parent ? 30 : 40;
			var avaterMt = parent ? 1.5 : 1;
			// canEdited, canDeleted, canReported
			var canEdited = false;
			var canDeleted = false;
			var canReported = false;
			if( header.state === "idle" ) {
				if( userId ) {
					if( hasUserPer ) { canEdited = true; canDeleted = true; }
					if( userId === mData.userId ) { canEdited = true; canDeleted = true; }
					if( userId === authorId ) { canDeleted = true; }
					//var strTest = "ผู้แต่ง: " + authorId + " ,สมาชิกที่ดู: " + userId + ", คนคอมเม้นต์: " + mData.userId;
				}
			}
			// text
			var text = mData.text;
			text = glob.toHtmlNewLine( text, true );
			text = Autolinker.link( text, { newWindow: false } );
			if( mData.repliedUserId ) {
				var uData = header.lUser[ mData.repliedUserId ];
				if( uData.userName ) { text = '<a href="' + '/user/' + uData.userName + '">' + uData.displayName + '</a>' + ' ' + text; }
				else { text = "<b>Deleted user</b> " + text; };
			} else if( mData.repliedUserId === null ) { // Guest
				text = "<b>Guest</b> " + text;
			}
			//
			if( mData.userId ) {
				var uData = header.lUser[ mData.userId ];
				urlAvatar = uData.urlAvatar;
				displayName = uData.displayName;
				urlUser = "/user/" + uData.userName;
			}
			else {
				//displayName = "Guest (" + mData.userIp + ")";
				displayName = "Guest";
			}
			// if( parent ) { return ( <div key={index}/> ); }
			return (
				<Box sx={{display: "flex", gap:2, mt:marginY,}}>
					{ urlUser ?
						( <IconButton sx={{mt:avaterMt, width:avatarSize, height:avatarSize,}} href={urlUser}><Avatar sx={{width:avatarSize, height:avatarSize,}} src={ urlAvatar }/></IconButton> ) :
						(<Avatar sx={{mt:avaterMt, width:avatarSize, height:avatarSize,}} src={ urlAvatar } /> )
					}
					<Box sx={{width: 1, display:"flex", alignItems: "flex-start", flexDirection: "column",}}>
						<Box sx={{display: "flex", alignItems: "center", gap:1, height:40}}>
							{ urlUser ?
								( <Link href={urlUser} underline="hover" color="mytheme">
									<Typography sx={{fontWeight: "bold"}} variant="body2">{ displayName }</Typography>
								</Link>) :
								<Typography sx={{fontWeight: "bold"}} variant="body2">{ displayName }</Typography>
							}
							<Typography color="myprim.dark" variant="body2">{ strDateCreated }</Typography>
							{ canDeleted ? (
								<Tooltip title="ลบ">
									<IconButton size="small" sx={{px:0, color: "myprim.main", "&:hover":{color:"myprim.dark"},}} disableRipple={true} onClick={ async ( ) => {
										header.state = "deleting";
										setWgs( );
										widgetConfirm.title[ 1 ]( "ลบความเห็น" );
										widgetConfirm.content[ 1 ]( ( mh.repiles.length > 0 ) ? "ลบความเห็นนี้รวมถึงความเห็นตอบกลับด้วย?" : "ลบความเห็นนี้?" );
										widgetConfirm.isOpen[ 1 ]( true );
										widgetConfirm.onClose.current = async ( isConfirm ) => {
											if( isConfirm ) {
												var arr = parent ? parent.repiles : header.comments;
												var offset = arr.indexOf( mh );
												//console.log( parent );
												//console.log( arr );
												if( offset > -1 ) {
													widgetWaiting.isOpen[ 1 ]( true );
													var src = {
														blogId: blogId,
														userId: userId,
														//commentId: ( parent ? parent.commentId : mh.commentId ),
														//replyId: ( parent ? mh.commentId : null ),
														commentId: ( mh.commentId ),
													};
													var res = ( await axios.post( "/backend/blogDeleteComment", src ) ).data;
													if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
													glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "ลบความเห็นเรียบร้อยแล้ว" } );
													//console.log( mh.commentId );
													arr.splice( offset, 1 );
													widgetWaiting.isOpen[ 1 ]( false );
												}
											}
											header.state = "idle";
											setWgs( );
										};
									}}>
										<DeleteIcon/>
									</IconButton>
								</Tooltip>
							) : null }
							{ canEdited ? (
								<Tooltip title="แก้ไข">
									<IconButton size="small" sx={{px:0, color: "myprim.main", "&:hover":{color:"myprim.dark"},}} disableRipple={true} onClick={ async ( ) => {
										header.state = "editing";
										header.mhReply = mh;
										header.parentReply = parent;
										mh.op = { status: "editing" };
										setWgs( );
									}}>
										<EditIcon/>
									</IconButton>
								</Tooltip>
							) : null }
							{ canReported ? (
								<Tooltip title="รายงาน">
									<IconButton size="small" sx={{px:0, color: "myprim.main", "&:hover":{color:"myprim.dark"},}} disableRipple={true}>
										<FlagIcon/>
									</IconButton>
								</Tooltip>
							) : null }
							{/*<Typography>{ strTest }</Typography>*/}
						</Box>
						<Typography sx={{mb:1}} dangerouslySetInnerHTML={{__html: text}} />
						{ header.canComment ? 
							<Button variant="text" disabled={ header.state !== "idle" } sx={{px: 0, minWidth:0, color: "primary.main", "&:hover":{bgcolor:"transparent"},}} onClick={ async ( ) => {
								header.state = "replying";
								header.mhReply = mh;
								header.parentReply = parent;
								//console.log( parent );
								setWgs( );
							}}>ตอบกลับ</Button>
						: null }
						{ ( (header.state === "replying") && (header.mhReply === mh) ) ? (
							<Box sx={{width: 1}}>
								<NewComment alwaysShowBt onCancelled={ ( ) => {
									header.state = "idle";
									delete header.mhReply;
									delete header.parentReply;
									setWgs( );
								} } onCommented={ async ( text ) => {
									if( glob.state.current !== "idle" ) { return; }
									glob.state.current = "wait";
									var src = { };
									src.blogId = blogId;
									src.userId = userId;
									src.text = text;
									src.repliedCommentId = header.mhReply.commentId;
									src.repliedUserId = undefined;
									var mh = header.mhReply;
									if( header.parentReply ) {
										mh = header.parentReply;
										src.repliedCommentId = header.parentReply.commentId;
										src.repliedUserId = header.mhReply.mentData.userId;
									}
									//console.log( header.mhReply );
									//console.log( header.parentReply );
									header.state = "idle";
									delete header.mhReply;
									delete header.parentReply;
									//
									var res = ( await axios.post( "/backend/blogPostComment", src ) ).data;
									if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
									else {
										glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "ส่งความเห็นเรียบร้อยแล้ว" } );
										// add new comment to mHeaders
										res.comment.mentData = res.mentData;
										if( mh ) { mh.repiles.push( res.comment ); }
										setWgs( );
									}
									//console.log( mHeader );
									//console.log( src );
									glob.state.current = "idle";
								} }/>
							</Box>
						) : null }
						{ strRepNum ? (
							<Button variant="text" startIcon={repNumIcon} sx={{px: 0, color: "primary.main", minWidth:0, "&:hover":{bgcolor:"transparent"},}} disableRipple={true} disabled={header.state !== "idle"} onClick={ async ( ) => {
								if( glob.state.current !== "idle" ) { return; }
								glob.state.current = "wait";
								if( mh.isShowReply ) {
									mh.isShowReply = false;
									setWgs( );
								} else {
									mh.isShowReply = true;
									var repiles = mh.repiles;
									if( !repiles[ 0 ].mentData ) { // init show these repiles
										repiles[ 0 ].op.status = "loadingComment";
										setWgs( );
										await addList( repiles, 0, repiles[ 0 ].op.num );
										delete repiles[ 0 ].op;
										//console.log( mh );
									}
									setWgs( );
								}
								glob.state.current = "idle";
							}}>
								{ strRepNum }
							</Button>
						) : null }
					</Box>
				</Box>
			);
		};
		//
		const MoreComment = ( props ) => {
			var { mh, offset, mhs } = wg;
			var mentBegin = offset + 1;
			var mentEnd = offset + mh.op.num;
			var text = "";
			if( mentBegin === mentEnd ) { text = "ความเห็นที่ " + mentBegin; }
			else { text = "ความเห็นที่ " + mentBegin + " - " + mentEnd; }
			return (
				<Box sx={{display: "flex", justifyContent: "center"}}>
					<Button variant="text" sx={{px: 0, color: "primary.main", minWidth:0, "&:hover":{bgcolor:"transparent"},}} disableRipple={true} onClick={ async ( ) => {
						if( glob.state.current !== "idle" ) { return; }
						glob.state.current = "wait";
						mh.op.status = "loadingComment";
						setWgs( );
						await addList( mhs, offset, mh.op.num );
						//console.log( mhs );
						delete mh.op;
						setWgs( );
						glob.state.current = "idle";
					} } > { text } </Button>
				</Box>
			);
		};
		//
		const CircularLoading = ( props ) => {
			return (
				<Box sx={{display: "flex", justifyContent: "center"}}>
					<CircularProgress />
				</Box>
			);
		};
		//
		const ReplyEditing = ( props ) => {
			var { mh } = wg;
			return (
				<Box sx={{mt:2}}>
					<NewComment alwaysShowBt dfText={mh.mentData.text} onCancelled={ ( ) => {
						header.state = "idle";
						//console.log( header.mhReply );
						delete header.mhReply.op;
						delete header.mhReply;
						delete header.parentReply;
						setWgs( );
					} } onCommented={ async ( text ) => {
						if( glob.state.current !== "idle" ) { return; }
						glob.state.current = "wait";
						//
						var src = {
							blogId: blogId,
							userId: userId,
							commentId: header.mhReply.commentId,
							text: text,
						};
						var res = ( await axios.post( "/backend/blogEditComment", src ) ).data;
						if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
						glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "แก้ไขความเห็นเรียบร้อยแล้ว" } );
						//
						var mentData = header.mhReply.mentData;
						mentData.text = text;
						mentData.isEdited = true;
						delete header.mhReply.op;
						delete header.mhReply;
						delete header.parentReply;
						header.state = "idle";
						setWgs( );
						glob.state.current = "idle";
					} }/>
				</Box>
			);
		};
		//
		var ren = ( <div/> );
		switch( wg.renType ) {
			case "moreComment": ren = ( <MoreComment/> ); break;
			case "circular": ren = ( <CircularLoading/> ); break;
			case "comment": ren = ( <ShowComment/> ); break;
			case "editing": ren = ( <ReplyEditing/> ); break;
		}
		return wg.parent ? (
			<Box key={index} sx={{display: "flex", width: 1}}>
				<Box sx={{ width: 60 }}/>
				<Box sx={{width: 1}}>
					{ ren }
				</Box>
			</Box>
		) : (
			<Box key={index}>
				{ ren }
			</Box>
		);
	};
	//
	React.useEffect( ( ) => {
		//
		const convertComments = ( srcs ) => {
			if( !srcs ) { return [ ]; }
			var dsts = [ ];
			var lsrc = { };
			srcs.map( ( src, index ) => { src.repiles = [ ]; lsrc[ src.commentId ] = src; } ); // lsrc
			// dsts
			srcs.map( ( src, index ) => {
				var parentId = src.parentId;
				delete src.parentId;
				if( parentId ) {
					var parent = lsrc[ parentId ];
					if( parent ) { parent.repiles.push( src ); }
				} else {
					dsts.push( src );
				};
			} );
			//
			return dsts;
		};
		//
		const fetchData = async ( ) => {
			if( !header.isInit ) {
				header.isInit = true;
				header.state = "idle";
				header.lUser = { };
				header.comments = convertComments( header.comments );
				// header.canComment
				header.canComment = false;
				if( canComment ) {
					if( glob.userInfo[ 0 ].userId ) { header.canComment = true; }
					else if( canGuestComment ) { header.canComment = true; }
				}
				// add own user to lUser
				if( glob.userInfo[ 0 ].userId ) {
					var info = glob.userInfo[ 0 ];
					header.lUser[ info.userId ] = info;
				}
				//
//				await addList( header.comments, 0, FragmentComment ); // initial comments show
				markCommentWaitingLoaded( FragmentComment, FragmentReply );
//				console.log( header );
			}
			//
			setWgs( );
			// console.log( header );
		};
		fetchData( );
		//
	}, [header]);
	//
	const renEnable = (
		<>
			<NewComment disableShow={!header.canComment} onCommented={ async ( text ) => {
				if( glob.state.current !== "idle" ) { return; }
				glob.state.current = "wait";
				var src = { };
				src.blogId = blogId;
				src.userId = userId;
				src.repliedUserId = undefined;
				src.repliedCommentId = null;
				src.text = text;
				var res = ( await axios.post( "/backend/blogPostComment", src ) ).data;
				if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
				else {
					glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "ส่งความเห็นเรียบร้อยแล้ว" } );
					// add new comment to mHeaders
					const mHeaders = header.comments;
					res.comment.mentData = res.mentData;
					mHeaders.push( res.comment );
					setWgs( );
				}
				glob.state.current = "idle";
			} }/>
			{ widgets[ 0 ].map( getRenWg ) }
		</>
	);
	//
	const renDisable = (
		<Box sx={{display: "flex", justifyContent: "center"}}>
			<Typography>คอมเม้นต์ถูกปิดนะ</Typography>
		</Box>
	);
	//
	return (
		<>
			<Divider sx={{mt:1, mb: 2}} />
			{ canComment ? renEnable : renDisable }
			<Box sx={{mb:12}}/>
		</>
	);
};


