
import React, { Component } from "react";
import { Box, Divider, Container, Typography, Grid, TextField, Avatar, Link, Input, Button, Tabs, Tab } from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";
import axios from "axios";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";

export default ( ) => {
	glob.init( );
	const emailName = React.useRef( null );
	const [ errPassword, setErrPassword ] = React.useState( "" );
	const [ errNewPassword, setErrNewPassword ] = React.useState( "" );
	const [ errNewPassword2, setErrNewPassword2 ] = React.useState( "" );
	const [ errEmail, setErrEmail ] = React.useState( "" );
	const renderHeaderbar = widgetHeaderbar.init( );
	const tabValue = React.useState( 1 );
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; }
		glob.setTitle( "Account settings" );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId ) { emailName.current.value = glob.userInfo[ 0 ].email; }
		else if ( glob.userInfo[ 0 ].userId === null ) { glob.nextPage( [ "/login?redirect="+glob.url64, { } ] ); }
	}, [ glob.userInfo[ 0 ] ] );
	//
	const submitPressed = async ( event ) => {
		event.preventDefault( );
		if( glob.state.current !== "idle" ) { return; }
		glob.state.current = "wait";
		//
		var form = event.target;
		var obj = { };
		obj.password = form.elements[ "password" ].value;
		obj.email = emailName.current.value;
		obj.newPassword = form.elements[ "newPassword" ].value;
		obj.newPassword2 = form.elements[ "newPassword2" ].value;
		obj.userId = glob.userInfo[ 0 ].userId;
		var res = ( await axios.post( "/backend/userSettingAccount", obj ) ).data;
		//
		if( res.isSuccess ) {
			//form.elements[ "password" ].value = "";
			if( res.status == "changed" ) {
				glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "เปลี่ยนแปลงเรียบร้อย" } );
				glob.state.current = "idle";
			}
			else if( res.status == "toHome" ) {
				glob.nextPage( [ "/", { state: { snackType: "success", snackMsg: "เปลี่ยนแปลงเรียบร้อย, ต้อง login เข้าใช้งานใหม่นะ" } } ] );
			}
			else { // "unchanged"
				glob.refreshSnack[ 1 ]( { snackType: "success", snackMsg: "ไม่ได้เปลี่ยนไรนะ" } );
				glob.state.current = "idle";
			}
		}
		else if( res.errorOther !== "" ) {
			glob.nextPage( [ "/", { state: { snackType: "error", snackMsg: res.errorOther } } ] );
		}
		else {
			setErrPassword( res.errPassword );
			setErrNewPassword( res.errNewPassword );
			setErrEmail( res.errEmail );
			glob.state.current = "idle";
		}
	};
	//
	const ren = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{mt:5, mb:5}}>
				<Box sx={{mb: 4, display: "flex", flexDirection: "column", alignItems: "center"}}>
					<Box sx={{display: "flex", alignItems: "baseline"}}>
						<Avatar sx={{mr: 1, bgcolor: "mytheme"}}>
							<SettingsIcon/>
						</Avatar>
						<Typography component="h1" variant="h4">Settings</Typography>
					</Box>
				</Box>
				<Tabs value={tabValue[0]} onChange={ ( event, value ) => { tabValue[ 1 ]( value ); } } sx={{mb:5}}>
					<Tab label="Profile" component="a" href="/setting_profile" />
					<Tab label="Account" component="a" href="/setting_account" onClick={ ( event, mouseEvent ) => event.preventDefault( ) } />
				</Tabs>
				<Box sx={{mt:2}}>
					<form noValidate id="fromSettings" onSubmit={submitPressed}>
						<Grid container spacing={2}>
							<Grid item xs={12} sx={{mt:1}}>
								<TextField variant="outlined" required fullWidth name="password" label="Password ปัจจุบัน" type="password" id="password"
									autoComplete="current-password"
									error={ errPassword !== "" }
									helperText={ errPassword }
									onChange={ ( event ) => {
										setErrPassword( "" );
									} }
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography>ต้องกรอก password ปัจจุบันด้วยนะ</Typography>
							</Grid>
							<Grid item xs={12}> <Divider/> </Grid>
							<Grid item xs={12} sx={{mt:2}}>
								<TextField
									variant="outlined" fullWidth required id="email" label="Email Address" name="email" autoComplete="email"
									inputRef={emailName}
									error={ errEmail !== "" }
									helperText={ errEmail }
									onChange={ ( event ) => { setErrEmail( "" ); } }
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography>Email ไว้สำหรับ reset password และอะไรเดี๋ยวดูอีกที</Typography>
							</Grid>
							<Grid item xs={12}> <Divider/> </Grid>
							<Grid item xs={12} sx={{mt:2}}>
								<TextField variant="outlined" fullWidth name="newPassword" label="Password ใหม่" type="password" id="newPassword"
									autoComplete="current-password"
									error={ errNewPassword !== "" }
									helperText={ errNewPassword }
									onChange={ ( event ) => { setErrNewPassword( "" ); } }
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField variant="outlined" fullWidth name="newPassword2" label="Confirm Password ใหม่" type="password" id="newPassword2"
									autoComplete="current-password"
									error={ errNewPassword2 !== "" }
									helperText={ errNewPassword2 }
									onChange={ ( event ) => { setErrNewPassword2( "" ); } }
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography>สำหรับเปลี่ยน password ใหม่</Typography>
							</Grid>
							<Grid item xs={12}> <Divider/> </Grid>
							<Grid item xs={12} sx={{mt: 2, mb: 8}}>
								<Button variant="contained" color="myprim" type="submit" form="fromSettings">Save</Button>
							</Grid>
						</Grid>
					</form>
				</Box>
			</Container>
		</div>
	);
	//
	return glob.render( glob.userInfo[ 0 ].userId ? <div>{ren}</div> : <div/> );
};

