
import React from "react";
import { useParams } from "react-router-dom";
import { Box, Avatar, Divider, Container, Typography, Link, Button, TextField } from "@mui/material";
import axios from "axios";
//import address from "address";
import glob from "./glob";
import widgetHeaderbar from "./widget_headerbar";

import BlogControl, {addMoreBlogData} from "./props_blog_control";

export default ( ) => {
	glob.init( );
	const params = useParams( );
	const blogId = params.id;
	const renderHeaderbar = widgetHeaderbar.init( );
	const [ state, setState ] = React.useState( "wait" );
	const stBlog = React.useState({});
	//
	React.useEffect( ( ) => {
		if( !glob.isReady ) { return; };
		//console.log( address.ip( ) );
	}, [ glob.isReady ] );
	//
	React.useEffect( ( ) => {
		if( glob.userInfo[ 0 ].userId === undefined ) { return; }
		const fetchData = async ( ) => {
			setState( "wait" );
			var res = (await axios.post("/backend/blogGetList", {listType: "blog", blogId: blogId})).data;
			if( res.err ) { glob.nextPage( [ "/", { replace: true, state: { snackType: "error", snackMsg: res.err } } ] ); return; }
			var blog = res.blogs[0];
			glob.setTitle( blog.title );
			stBlog[1](addMoreBlogData(blog, res.lUser));
			//
			setState( "idle" );
		};
		fetchData( );
	}, [glob.userInfo[0]]);
	//
	const ren = (
		<div>
			{ renderHeaderbar }
			<Container component="main" maxWidth="md" sx={{mt:4, mb:4}}>
				<BlogControl blog={stBlog[0]} disableStatus alwaysExpand onDeleted={(err) => {
					var state = err ? {snackType: "error", snackMsg: err} : {snackType: "success", snackMsg: "ลบเรียบร้อยแล้ว"};
					glob.nextPage(["/", {replace: true, state: state}]);
				}}/>
			</Container>
		</div>
	);
	//
	return glob.render( ( state === "wait") ? null : ren );
};

