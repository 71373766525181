
import React from "react";
import { Box, Typography, Link } from "@mui/material";
import axios from "axios";
import glob from "./glob";

export default (props) => {
	var { pageNo, pageNum, strRoute } = props;
	return (
		<Box sx={{display: "flex"}}>
			<Box sx={{ flexGrow: 1 }} />
			{ pageNo > 1 ? (
				<Link href={strRoute+"?page="+(pageNo-1)} underline="hover" color="mytheme">
					<Typography sx={{mr: 2}}>Previous</Typography>
				</Link>
			) : null }
			<Link href={strRoute+"?page="+(pageNo+1)} underline="hover" color="mytheme">
				<Typography>Next</Typography>
			</Link>
		</Box>
	);
};

